import router from "@/router";
import Cookies from 'js-cookie';
import moment from 'moment';

const getDefaultState = () => {
    return {
        briefcase_version: localStorage.getItem('ls-version') || '0.1.0',
        site_load: true,
        user_theme: localStorage.getItem('user_theme'),
        internet_connection: true,
        social_windows: false,
        is_mobile: false,
        page_key: 1,
        before_auth_link: sessionStorage.getItem('before_auth_link') || null,
        before_edit_link: null,
        image_editor_data: null,
        placeholder_notice_data: null,
        locale: {},
        user_locale: {},
        scroll_position: {},
        document_visible: true,
        development_host: location.host !== 'localit.io'
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        MOBILE: state => state.is_mobile,
        LOCALE: state => state.locale,
        USER_LOCALE: state => state.user_locale
    },
    actions: {
        CLOSE_PAGE_BACK({state}, route_name) {
            if (window.history.length > 1) router.go(-1)
            else if (route_name) router.push({name: route_name})
            else router.push(state.before_auth_link || '/')
        },
        UPDATE_BRIEFCASE_VERSION({commit}, version) {
            const
                ls_version = localStorage.getItem('ls-version'),
                ls_version_correct = ls_version ? Number(ls_version.replaceAll('.', '')) : 0,
                new_version_correct = Number(version.replaceAll('.', ''));

            commit('CHANGE_BRIEFCASE_VERSION', version);

            if (!ls_version_correct || (ls_version_correct !== new_version_correct)) {
                if (ls_version_correct < 394) {
                    let cookies = Object.keys(Cookies.get());
                    for (let i = 0; i < cookies.length; i++) Cookies.remove(cookies[i])
                }

                commit('CLEAR_USER');
                commit('CLEAR_STATISTICS');
                commit('CLEAR_RATINGS');
                commit('CLEAR_ATTRIBUTES');
                commit('CLEAR_LANGUAGES');
                commit('CLEAR_TASKS');
                commit('CLEAR_NEED_ACTION_TASKS');
                commit('CLEAR_MODERATION_STORE');
                commit('CLEAR_CHAT');
                commit('CLEAR_CACHE');
                commit('CLEAR_NEWS');

                localStorage.clear();
                location.reload(true);
            }
        },
    },
    mutations: {
        SAVE_INTERNET_CONNECTION: (state, boolean) => state.internet_connection = boolean,
        SAVE_SOCIAL_WINDOWS: (state, value) => state.social_windows = value,
        SAVE_BEFORE_AUTH_LINK: (state, link) => {
            state.before_auth_link = link
            if (link) sessionStorage.setItem('before_auth_link', link)
            else sessionStorage.removeItem('before_auth_link')
        },
        SAVE_BEFORE_EDIT_LINK: (state, link) => state.before_edit_link = link,
        SAVE_SCROLL: (state, position) => state.scroll_position[position.name] = position.position,
        CLEAR_SCROLL: (state) => state.scroll_position = {},
        LOAD_SITE: (state, boolean) => state.site_load = boolean,
        CHANGE_DOCUMENT_VISIBLE: (state, boolean) => state.document_visible = boolean,
        SAVE_LOCALE: (state, locale) => {
            if (locale) state.locale = locale;
            moment.locale(locale.code);
        },
        SAVE_USER_LOCALE: (state, locale) => {
            if (locale) {
                state.user_locale = locale;
                state.locale = locale;
                moment.locale(locale.code);
                document.documentElement.setAttribute('lang', locale.code);
            }
        },
        CHANGE_SITE_THEME_PREFERS(state, data) {
            let val;

            if (data.theme) val = data.theme;
            else {
                let data_theme = document.documentElement.getAttribute('data-theme');
                val = (data_theme === 'dark') ? 'light' : 'dark';
            }

            localStorage.setItem('user_theme', val);
            state.user_theme = val;

            let root = document.documentElement;
            if (data.animation) root.classList.add('toggle-animation');
            root.setAttribute('data-theme', val.trim());
            setTimeout(() => {
                root.classList.remove('toggle-animation');
                root.style.backgroundColor = null;
            }, 500);
        },
        SAVE_IMAGE_EDITOR_DATA: (state, data) => state.image_editor_data = data,
        SAVE_PLACEHOLDER_NOTICE_DATA: (state, data) => state.placeholder_notice_data = data,
        SAVE_IS_MOBILE: (state, bool) => state.is_mobile = bool,
        UPDATE_PAGE: (state) => state.page_key += 1,
        async CHANGE_BRIEFCASE_VERSION(state, version) {
            await localStorage.removeItem('briefcase')
            state.briefcase_version = version
            await localStorage.setItem('ls-version', version)
        }
    },
}
