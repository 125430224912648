const getDefaultState = () => {
    return {
        deleted_key_ids: [],
        projects_languages_filter: {},
        project_search_filters: {},
        hide_placeholder_notice: false,
        list_placeholder_code_mode: false,
        projects_download_options: {},
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        DOWNLOAD_OPTIONS: (state, getters) => state.projects_download_options[getters.SELECTED_PROJECT.uuid] || {}
    },
    actions: {
        SAVE_PROJECT_LANGUAGES_FILTER({state, getters}, languages_ids) {
            state.projects_languages_filter[getters.SELECTED_PROJECT.uuid] = languages_ids
        },
        SAVE_PROJECT_SEARCH_FILTERS({state, getters}, filters) {
            if (!filters) state.project_search_filters = {}
            else state.project_search_filters = {...filters, project_uuid: getters.SELECTED_PROJECT.uuid}
        },
        SAVE_DOWNLOAD_OPTIONS({state, getters}, payload) {
            let p_uuid = getters.SELECTED_PROJECT.uuid;
            if (!(p_uuid in state.projects_download_options)) state.projects_download_options[p_uuid] = {};
            state.projects_download_options[p_uuid][payload[0]] = payload[1];
        }
    },
    mutations: {
        SAVE_DELETED_KEY_IDS(state, payload) {
            if (payload.delete) {
                state.deleted_key_ids = state.deleted_key_ids.filter(id => id !== payload.key_id);
            } else if (payload.add) {
                state.deleted_key_ids.push(payload.key_id);
                state.deleted_key_ids = Array.from(new Set(state.deleted_key_ids));
            }
        },
        TOGGLE_LIST_PLACEHOLDER_CODE_MODE: (state) => state.list_placeholder_code_mode = !state.list_placeholder_code_mode,
        CHANGE_HIDE_PLACEHOLDER_NOTICE: (state) => state.hide_placeholder_notice = true,
        DELETE_PROJECT_DOWNLOAD_OPTIONS: (state, uuid) => delete state.projects_download_options[uuid],
        CLEAR_CACHE: (state) => Object.assign(state, getDefaultState())
    },
}
