import axios from "axios";
import { saveAs } from 'file-saver';
import {decode} from 'html-entities';
import bus from "@/assets/js/helper.js";

const getDefaultState = () => {
    return {
        detail_key: null,
        detail_key_comments: [],
        detail_key_comments_all_loaded: false,
        keys_list: [],
        keys_limit: 20,
        keys_all_loaded: false,
        search_status: false,
        search_text: "",
        search_sections_ids: [],
        search_statuses: [],
        search_platforms: ["android", "ios", "web", "other"],
        order_by: "date-desc",
        searched_keys: [],
        translations_keys_list: [],
        translations_keys_all_loaded: false,
        translations_keys_limit: 30,
        translations_keys_params: {},
        translation_key: null,
        keys_update: true,
        edit_placeholder_code_mode: false,
        translation_placeholder_code_mode: false,
        active_translation_key_id: null,
        change_main_translate_data: null,
        system_comment_modifier: 0,
    }
}

export default {
    state: () => getDefaultState(),
    actions: {
        async GET_KEYS_LIST({state, getters, commit, rootState}, payload = {}) {
            let without_section_check = false,
                searched_languages_ids = rootState.cache.projects_languages_filter[getters.SELECTED_PROJECT.uuid] || [],
                params = {
                    project_uuid: getters.SELECTED_PROJECT.uuid,
                    limit: state.keys_limit
                };

            if (payload.sections_ids) {
                if (payload.sections_ids.includes('without_section')) {
                    without_section_check = true;
                    params['is_without_section'] = '1'
                }
                params['sections_ids'] = payload.sections_ids.filter(id => id !== 'without_section')
            } else if (state.search_sections_ids.length > 0) {
                if (state.search_sections_ids.includes('without_section')) params['is_without_section'] = '1'
                params['sections_ids'] = state.search_sections_ids.filter(id => id !== 'without_section')
            }

            if (!without_section_check) {
                commit("CHANGE_SEARCH_STATUS", Boolean(state.search_text.length || state.search_sections_ids.length));
            }

            if (payload.last_id) params['last_id'] = payload.last_id
            else if (state.keys_all_loaded) commit("SAVE_KEYS_ALL_LOADED", false)

            if (state.search_text) params['search'] = state.search_text
            if ((searched_languages_ids.length > 0) && (searched_languages_ids.length < getters.SELECTED_PROJECT.project_languages.length)) params['project_languages_ids'] = searched_languages_ids
            if (state.search_statuses.length > 0) params['translations_statuses'] = state.search_statuses
            if (state.search_platforms.length < 4) params['platforms'] = state.search_platforms
            if (state.order_by !== 'date-desc') {
                if (state.order_by === 'date-asc') {
                    params['sort_by'] = 'date'
                    params['order_by'] = 'asc'
                } else if (state.order_by === 'name-asc') {
                    params['sort_by'] = 'name'
                    params['order_by'] = 'asc'
                } else if (state.order_by === 'name-desc') {
                    params['sort_by'] = 'name'
                    params['order_by'] = 'desc'
                }
            }

            await axios
                .get(this.$api_url_locals + "/key/list", {params})
                .then(async (resp) => {
                    const keys = resp.data.data.keys;

                    keys.forEach(k => {
                        if (k.translation_type === 'plural') {
                            k.translations.forEach(t => t.values = bus.sortedPluralValues(t.values))
                        }
                    })

                    if (!without_section_check && state.search_status) {
                        await commit("SAVE_SEARCHED_KEYS", {clear: !payload.last_id, keys});
                        if (keys.length < state.keys_limit) await commit("SAVE_KEYS_ALL_LOADED", true);
                    } else if (without_section_check || params.sections_ids || params.is_without_section || state.search_sections_ids.length) {
                        await commit("SAVE_KEYS_IN_SECTION", {
                            section_id: params.sections_ids?.[0] || state.search_sections_ids?.[0],
                            keys,
                            download: !!payload.last_id,
                            all_loaded: keys.length < state.keys_limit
                        })
                    } else {
                        await commit("SAVE_KEYS_LIST", {
                            keys,
                            download: !!payload.last_id,
                            all_loaded: keys.length < state.keys_limit
                        })
                        if (!keys.length && !state.keys_list.length && !state.search_statuses.length && (state.search_platforms.length === 4)) {
                            await commit("SAVE_SELECTED_PROJECT_EMPTY_STATUS", true)
                        } else {
                            await commit("SAVE_SELECTED_PROJECT_EMPTY_STATUS", false)
                        }
                        if (keys.length < state.keys_limit) {
                            await commit("SAVE_KEYS_ALL_LOADED", true)
                        }
                    }

                    state.keys_update = false;
                })
                .catch(err => {
                    console.error(err)
                    throw new Error(err.code)
                })
        },
        async GET_TRANSLATIONS_KEYS_LIST({state, getters, commit}, {mode, type, statuses}) {
            if (!type) {
                await commit("CHANGE_TRANSLATIONS_KEYS_LIST_LOADING", true);
                await commit("SAVE_TRANSLATIONS_KEYS_LIST", {clear: true});
            }

            let params = {
                project_uuid: getters.SELECTED_PROJECT.uuid,
                original_project_language_id: getters.TRANSLATION_MODE_SETTINGS.o_lang.project_language_id,
                limit: state.translations_keys_limit,
                project_languages_ids: getters.TRANSLATION_MODE_SETTINGS.d_lang.map(l => l.project_language_id)
            }

            if (statuses?.length) {
                params['translations_statuses'] = statuses
            } else {
                params['translations_statuses'] = getters.TRANSLATION_MODE_SETTINGS.statuses
            }

            if (mode === 'translate') {
                params['translation_project_language_id'] = getters.TRANSLATION_MODE_SETTINGS.t_lang.project_language_id
            } else {
                params['verification_project_language_id'] = getters.TRANSLATION_MODE_SETTINGS.t_lang.project_language_id
            }

            if (state.translations_keys_params.sections_ids) {
                if (state.translations_keys_params.sections_ids.includes('without_section')) params['is_without_section'] = '1'
                params['sections_ids'] = state.translations_keys_params.sections_ids.filter(id => id !== 'without_section')
            }

            if (type === 'download') params['last_id'] = state.translations_keys_list[state.translations_keys_list.length - 1].id
            else commit("SAVE_TRANSLATIONS_KEYS_ALL_LOADED", false)

            if (state.translations_keys_params.search) params['search'] = state.translations_keys_params.search
            if (state.translations_keys_params.order_by !== 'date-desc') {
                if (state.translations_keys_params.order_by === 'date-asc') {
                    params['sort_by'] = 'date'
                    params['order_by'] = 'asc'
                } else if (state.translations_keys_params.order_by === 'name-asc') {
                    params['sort_by'] = 'name'
                    params['order_by'] = 'asc'
                } else if (state.translations_keys_params.order_by === 'name-desc') {
                    params['sort_by'] = 'name'
                    params['order_by'] = 'desc'
                }
            }

            await axios
                .get(this.$api_url_locals + "/key/trans_verif_mode/list", {params})
                .then((resp) => {
                    const keys = resp.data.data.keys;
                    keys.forEach(k => {
                        if (k.translation_type === 'plural') {
                            k.translations.forEach(t => t.values = bus.sortedPluralValues(t.values))
                        }
                    })
                    commit("SAVE_TRANSLATIONS_KEYS_LIST", {keys: keys, download: type === 'download'});
                    if (keys.length < state.translations_keys_limit) commit("SAVE_TRANSLATIONS_KEYS_ALL_LOADED", true);
                })
                .catch(err => {
                    throw new Error(err.code);
                })
                .finally(() => {
                    commit("CHANGE_TRANSLATIONS_KEYS_LIST_LOADING", false);
                })
        },
        async CREATE_NEW_KEY({dispatch, commit, getters}, payload) {
            let fData = new FormData();
            fData.append('project_uuid', getters.SELECTED_PROJECT.uuid);
            fData.append('name', payload.name);
            if (payload.value) fData.append('value', payload.value);
            else if (payload.values) fData.append('values', JSON.stringify(payload.values));
            if (payload.name_plural) fData.append('name_plural', payload.name_plural);
            if (payload.translation_type) fData.append('translation_type', payload.translation_type);
            for (let i = 0; i < payload.platforms.length; i++) fData.append('platforms[]', payload.platforms[i]);

            if (payload.sections_ids) for (let i = 0; i < payload.sections_ids.length; i++) fData.append('sections_ids[]', payload.sections_ids[i]);
            if (payload.description) fData.append('description', payload.description);
            if (payload.translator_limit) fData.append('translator_limit', payload.translator_limit);

            await axios
                .post(this.$api_url_locals + "/key", fData)
                .then(async response => {
                    let key = response.data.data.key;

                    if (key.translation_type === 'plural') key.translations.forEach(t => t.values = bus.sortedPluralValues(t.values));

                    commit("SAVE_DETAIL_KEY", key);
                    commit("SAVE_NEW_KEY_IN_SECTION", {
                        key: key,
                        sections_ids: payload.sections_ids
                    });
                    commit("SAVE_NEW_KEY_IN_LIST", key);

                    await commit("SAVE_SELECTED_PROJECT_EMPTY_STATUS", false);

                    if (payload.images) {
                        await dispatch("SEND_NEW_KEY_COMMENT", {
                            key_id: key.id,
                            images: payload.images
                        });
                    }

                    await commit("ALERT_SHOW", {name: 'addition', content: 'key_is_added'});
                })
                .catch(err => {
                    if (!['ERROR_KEY_EXISTS', 'ERROR_TRANSLATION_EXISTS'].includes(err.code)) {
                        dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    }
                    throw Error(err.code);
                })
        },
        async EDIT_KEY({dispatch, commit}, payload) {
            let fData = new FormData();
            fData.append('name', payload.name);
            for (let i = 0; i < payload.platforms.length; i++) fData.append('platforms[]', payload.platforms[i]);
            if (payload.sections_ids) for (let i = 0; i < payload.sections_ids.length; i++) fData.append('sections_ids[]', payload.sections_ids[i]);
            if (payload.description) fData.append('description', payload.description);
            if ("name_plural" in payload) fData.append('name_plural', payload.name_plural);
            if (payload.translator_limit) fData.append('translator_limit', payload.translator_limit);

            await axios
                .put(this.$api_url_locals + "/key/" + payload.id, fData)
                .then(response => {
                    commit("UPDATE_KEY_IN_LIST", response.data.data.key);
                    commit("UPDATE_KEY_IN_SECTION", response.data.data.key);
                })
                .catch(err => {
                    if (!['ERROR_KEY_EXISTS', 'ERROR_TRANSLATION_EXISTS'].includes(err.code)) {
                        dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    }
                    throw Error(err.code);
                })
        },
        async DELETE_KEY({commit, rootState}, key_id) {
            await axios
                .delete(this.$api_url_locals + "/key/" + key_id)
                .then(() => {
                    commit("DELETE_KEY_FROM_LIST", key_id);
                    commit("DELETE_KEY_FROM_SECTIONS", key_id);
                    commit("DELETE_KEY_FROM_PROJECT");
                    commit("SAVE_DELETED_KEY_IDS", {'delete': 1, key_id});

                    if (!rootState.sections.sections_list.length) commit("CHANGE_SECTIONS_MODE", false);
                })
                .catch(err => {
                    if (err.code === 'ERROR_KEY_NOT_EXIST') {
                        commit("SAVE_DELETED_KEY_IDS", {'delete': 1, key_id});
                    } else {
                        throw new Error(err.code);
                    }
                })
        },
        SAVE_DELETED_KEY({state, commit, rootState}, key_id) {
            let pk = state.keys_list.find(k => k.id === key_id);
            if (pk) pk['is_deleted'] = true;

            let schk = state.searched_keys.find(k => k.id === key_id);
            if (schk) schk['is_deleted'] = true;

            rootState.sections.sections_list.forEach(s => {
                if (s.keys) {
                    let sk = s.keys.find(k => k.id === key_id);
                    if (sk) sk['is_deleted'] = true;
                }
            })

            commit("SAVE_DELETED_KEY_IDS", {'add': 1, key_id});
        },
        CANCEL_DELETED_KEY({state, commit, rootState}, key_id) {
            let pk = state.keys_list.find(k => k.id === key_id);
            if (pk) delete pk['is_deleted'];

            let schk = state.searched_keys.find(k => k.id === key_id);
            if (schk) delete schk['is_deleted'];

            rootState.sections.sections_list.forEach(s => {
                if (s.keys) {
                    let sk = s.keys.find(k => k.id === key_id);
                    if (sk) delete sk['is_deleted'];
                }
            })

            commit("SAVE_DELETED_KEY_IDS", {'delete': 1, key_id});
        },
        GET_KEY({commit}, id) {
            return new Promise((resolve, reject) => {
                if (id === 0) {
                    reject('empty');
                    return ;
                }

                axios
                    .get(this.$api_url_locals + "/key/" + id)
                    .then((resp) => {
                        const key = resp.data.data.key;
                        if (key.translation_type === 'plural') key.translations.forEach(t => t.values = bus.sortedPluralValues(t.values));
                        commit("SAVE_DETAIL_KEY", key);

                        resolve(key);
                    })
                    .catch(err => {
                        reject(err.code);
                        throw new Error(err.code);
                    })
            })
        },
        async GET_KEY_COMMENTS({commit}, payload) {
            if (!payload.params.last_id && !payload.upload) {
                await commit("CHANGE_DETAIL_KEY_COMMENTS_ALL_LOADED", false);
                await commit("SAVE_DETAIL_KEY_COMMENTS", {clear: true});
            }

            if (payload.params.key_id === 0) return;

            const URL = this.$api_url_locals + (payload.system_type ? "/key/log/list" : "/key/comment/list");

            await axios
                .get(URL, {params: payload.params})
                .then((resp) => {
                    const comments = resp.data.data[payload.system_type ? 'key_logs' : 'comments'];
                    commit("SAVE_DETAIL_KEY_COMMENTS", {download: payload.params.last_id, comments});
                    if (comments.length < 20) commit("CHANGE_DETAIL_KEY_COMMENTS_ALL_LOADED", true);
                })
                .catch(err => {
                    throw new Error(err.code);
                })
        },
        async SEND_NEW_KEY_COMMENT({getters, commit}, payload) {
            let fData = new FormData();
            fData.append('key_id', payload.key_id);
            if (payload.text) fData.append('text', payload.text);
            if (payload.images?.length) for (let image of payload.images) fData.append('images', image.file_image);
            if (payload.files?.length) for (let file of payload.files) fData.append('files', file.file);
            if (payload.tagged_user_uuids) {
                for (let i = 0; i < payload.tagged_user_uuids.length; i++) {
                    fData.append('tagged_user_uuids[]', payload.tagged_user_uuids[i]);
                }
            }

            await axios
                .post(this.$api_url_locals + "/key/comment", fData)
                .then((resp) => {
                    commit("SAVE_DETAIL_KEY_NEW_COMMENTS", Object.assign(resp.data.data.comment, {
                        new_comment: true,
                        user: {
                            avatar: getters.USER.avatar,
                            is_dummy: false,
                            mini_avatar: getters.USER.mini_avatar,
                            nickname: getters.USER.nickname,
                            profile_url: getters.USER.profile_url
                        }
                    }))
                })
                .catch(err => {
                    throw new Error(err.code)
                })
        },
        async ADD_KEY_TRANSLATION({dispatch, commit}, payload) {
            await axios
                .post(this.$api_url_locals + "/translation", payload)
                .then((resp) => {
                    let translation = resp.data.data.translation;
                    if (translation.type === 'plural') translation.values = bus.sortedPluralValues(translation.values);
                    commit("SAVE_KEY_TRANSLATION", translation);
                    commit("UPDATE_SYSTEM_COMMENT_MODIFIER");
                    commit("UPDATE_PROJECT_INFO");
                })
                .catch(err => {
                    if (!['ERROR_KEY_EXISTS', 'ERROR_TRANSLATION_EXISTS'].includes(err.code)) {
                        dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    }
                    throw Error(err.code);
                })
        },
        async UPDATE_KEY_TRANSLATION({dispatch, commit}, payload) {
            let d = {update_type: payload.update_type}

            if (payload.values) d['values'] = payload.values
            else if (payload.value) d['value'] = payload.value

            await axios
                .patch(this.$api_url_locals + "/translation/" + payload.id, d)
                .then((resp) => {
                    let translation = resp.data.data.translation;
                    if (translation.type === 'plural') translation.values = bus.sortedPluralValues(translation.values);
                    commit("SAVE_KEY_TRANSLATION", {...translation, update_type: payload.update_type});
                    commit("UPDATE_SYSTEM_COMMENT_MODIFIER");
                })
                .catch(err => {
                    if (!['ERROR_KEY_EXISTS', 'ERROR_TRANSLATION_EXISTS'].includes(err.code)) {
                        dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    }
                    throw Error(err.code);
                })
        },
        async APPROVE_KEY_TRANSLATION({dispatch, commit}, translation_id) {
            await axios
                .post(this.$api_url_locals + "/translation/approve/" + translation_id)
                .then((resp) => {
                    commit("SAVE_KEY_TRANSLATION", resp.data.data.translation);
                    commit("UPDATE_SYSTEM_COMMENT_MODIFIER");
                })
                .catch(err => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        async CHANGE_TRANSLATION_STATUS({dispatch, commit}, payload) {
            await axios
                .patch(this.$api_url_locals + "/translation/status/" + payload.t_id, {status: payload.status})
                .then((resp) => {
                    commit("SAVE_KEY_TRANSLATION", resp.data.data.translation);
                    commit("UPDATE_SYSTEM_COMMENT_MODIFIER");
                })
                .catch(err => {
                    dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                    throw new Error(err);
                })
        },
        async DOWNLOAD_KEYS({getters, dispatch, commit}, params) {
            await axios({
                url: this.$api_url_locals + "/key/download",
                method: 'GET',
                params,
                responseType: 'blob'
            }).then((resp) => {
                let fileName;

                if (resp.headers["content-disposition"]) {
                    fileName = resp.headers["content-disposition"].split("filename=")[1];
                }

                saveAs(resp.data, fileName || 'locals.gz');

                if (params.translation_status) dispatch("SAVE_DOWNLOAD_OPTIONS", ['translations_status', params.translation_status]);
                else dispatch("SAVE_DOWNLOAD_OPTIONS", ['translations_status', 'all']);

                if (params.replacement_project_language_id) dispatch("SAVE_DOWNLOAD_OPTIONS", ['replacement_lang', params.replacement_project_language_id]);
                else dispatch("SAVE_DOWNLOAD_OPTIONS", ['replacement_lang', null]);

                if (params.project_languages_ids) dispatch("SAVE_DOWNLOAD_OPTIONS", ['languages', params.project_languages_ids]);
                if (params.file_format) dispatch("SAVE_DOWNLOAD_OPTIONS", ['file_format', params.file_format]);
                if (params.placeholder_format) {
                    let val = getters.DOWNLOAD_OPTIONS.placeholder_format || {};
                    val[params.file_format] = params.placeholder_format;
                    dispatch("SAVE_DOWNLOAD_OPTIONS", ['placeholder_format', val]);
                }
                if (params.plural_format) {
                    let val = getters.DOWNLOAD_OPTIONS.plural_format || {};
                    val[params.file_format] = params.plural_format;
                    dispatch("SAVE_DOWNLOAD_OPTIONS", ['plural_format', val]);
                }
                if (params.order_by && params.sort_by) dispatch("SAVE_DOWNLOAD_OPTIONS", ['sorting', `${params.order_by}-${params.sort_by}`]);
                if ('trim_spaces' in params) dispatch("SAVE_DOWNLOAD_OPTIONS", ['trim_spaces', params.trim_spaces]);
                if ('replace_breaks' in params) dispatch("SAVE_DOWNLOAD_OPTIONS", ['replace_breaks', params.replace_breaks]);

                dispatch("CLOSE_PAGE_BACK");
            }).catch(err => {
                let error = null;

                err.text()
                    .then(r => {
                        let e = JSON.parse(r)

                        if (e.error.code === 'ERROR_KEY_LIMIT_EXCEEDED') {
                            commit("SAVE_LIMIT_EXCEEDED_POPUP", "keys");
                        } else if (e.error.code === 'ERROR_TEAMMATE_LIMIT_EXCEEDED') {
                            commit("SAVE_LIMIT_EXCEEDED_POPUP", "teammates");
                        } else if (['ERROR_INCORRECT_FILE_DATA', 'VALIDATION_ERROR'].includes(e.error.code)) {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'error_incorrect_file'});
                        }

                        error = e.error
                    })
                    .catch(e => {
                        error = e
                    })

                throw new Error(error)
            })
        },
        UPLOAD_KEYS({getters, dispatch, commit}, payload) {
            let fData = new FormData();
            fData.append('project_language_id', payload.project_language_id);
            fData.append('project_uuid', getters.SELECTED_PROJECT.uuid);
            fData.append('file', payload.file);
            if (payload.options.includes('update_values')) fData.append('update_values', Boolean(true));
            if (payload.options.includes('delete_old_keys')) fData.append('delete_old_keys', Boolean(true));
            if (payload.options.includes('fill_empty_values')) fData.append('fill_empty_values', Boolean(true));
            if (payload.options.includes('convert_placeholders')) fData.append('convert_placeholders', Boolean(true));
            if (payload.options.includes('unescape_new_line')) fData.append('unescape_new_line', Boolean(true));

            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url_locals + "/key/upload", fData)
                    .then(async (resp) => {
                        await commit("SAVE_SELECTED_PROJECT_EMPTY_STATUS", false);
                        await commit("UPDATE_PROJECT_INFO");
                        await dispatch("GET_KEYS_LIST", {first_request: true});
                        await resolve(resp.data.data)
                    })
                    .catch(err => {
                        if (['ERROR_INCORRECT_FILE_DATA', 'VALIDATION_ERROR'].includes(err.code)) {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'error_incorrect_file'});
                        } else if (err.code === 'ERROR_KEY_LENGTH') {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'error_incorrect_key_length'});
                        } else if (!['ERROR_INCORRECT_FILE_LANGUAGE', 'ERROR_KEY_BLOCKED_BY_ORDER'].includes(err.code)) {
                            dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'});
                        }
                        reject(err.code);
                    })
            })
        },
        async LOAD_GOOGLE_TRANSLATE({commit, rootState}, request) {
            let tl, sl = 'auto', client = 'gtx', dt = 't', q = request.search,
                lang = await rootState.languages.languages.find(l => l.code === request.lang),
                lang_t = await rootState.languages.languages.find(l => l.code === request.lang_t);

            if (lang) sl = lang.code_two_signs;
            if (lang_t) tl = lang_t.code_two_signs;
            else tl = 'en';

            return new Promise((resolve, reject) => {
                if (lang_t) {
                    commit("CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS", true);
                    q = bus.convertTextForGoogleTranslate(q);

                    fetch('https://translate.googleapis.com/translate_a/single?' + new URLSearchParams({client, dt, sl, tl, q}))
                        .then(r => r.json())
                        .then(async resp => {
                            let translate = '',
                                sl = await rootState.languages.languages.find(l => l.code_two_signs === resp[2])?.code;

                            await resp[0]?.forEach(p => translate += p[0]);

                            let div = document.createElement('div');
                            div.innerHTML = translate;

                            Array
                                .from(div.querySelectorAll('span.notranslate'))
                                .filter(n => n.translate)
                                .forEach(n => n.replaceWith(n.innerText))

                            translate = decode(div.innerHTML);

                            await resolve({translate, sl});
                        })
                        .catch((err) => reject(err))
                        .finally(() => commit("CHANGE_LOADING_GOOGLE_TRANSLATE_STATUS", false))
                } else resolve({translate: request.search, sl: request.lang_t})
            })
        },
    },
    mutations: {
        SAVE_DETAIL_KEY(state, key) {
            state.detail_key = key;
        },
        SAVE_DETAIL_KEY_COMMENTS(state, payload) {
            if (payload.clear) state.detail_key_comments = [];
            else if (!payload.download) state.detail_key_comments = payload.comments;
            else state.detail_key_comments = state.detail_key_comments.concat(payload.comments);
        },
        SAVE_DETAIL_KEY_NEW_COMMENTS(state, comment) {
            state.detail_key_comments.unshift(comment);
        },
        CHANGE_DETAIL_KEY_COMMENTS_ALL_LOADED(state, boolean) {
            state.detail_key_comments_all_loaded = boolean;
        },
        SAVE_ORDER_BY(state, order_by) {
            state.order_by = order_by;
        },
        SAVE_KEYS_LIST(state, payload) {
            if (payload.download) state.keys_list = state.keys_list.concat(payload.keys);
            else state.keys_list = payload.keys;
        },
        SAVE_KEYS_ALL_LOADED(state, boolean) {
            state.keys_all_loaded = boolean
        },
        SAVE_TRANSLATIONS_KEYS_LIST(state, payload) {
            if (payload.clear) state.translations_keys_list = [];
            else if (payload.download) state.translations_keys_list = state.translations_keys_list.concat(payload.keys);
            else state.translations_keys_list = payload.keys;
        },
        SAVE_TRANSLATIONS_KEYS_ALL_LOADED(state, boolean) {
            state.translations_keys_all_loaded = boolean
        },
        SAVE_NEW_KEY_IN_LIST(state, key) {
            state.keys_list.unshift(key)
        },
        UPDATE_KEY_IN_LIST(state, key) {
            if (state.keys_list.length) {
                let list_key = state.keys_list.find(k => k.id === key.id);
                if (list_key) Object.assign(list_key, key);
            }
            if (state.searched_keys.length) {
                let list_key = state.searched_keys.find(k => k.id === key.id);
                if (list_key) Object.assign(list_key, key);
            }
        },
        DELETE_KEY_FROM_LIST(state, key_id) {
            if (state.keys_list.length) {
                let index = state.keys_list.findIndex(k => k.id === key_id);
                if (~index) state.keys_list.splice(index, 1);
            }
            if (state.searched_keys.length) {
                let index = state.searched_keys.findIndex(k => k.id === key_id);
                if (~index) state.searched_keys.splice(index, 1);
            }
        },
        SAVE_KEY_TRANSLATION(state, translation) {
            const update_type = translation.update_type;

            delete translation['update_type'];

            let updateMainTranslation = (key) => {
                key.translations.forEach(t => {
                    if (!t.is_main) {
                        if (update_type === 'translations_deletion') {
                            t.status = 'untranslated';
                            if (t.type === 'plural') for (let prop in t.values) t.values[prop] = "";
                            else t.value = '';
                        } else if (update_type === 'sending_notification') {
                            if (t.values) {
                                let do_it = true;
                                for (let prop in t.values) {
                                    if (!t.values[prop].length) {
                                        do_it = false;
                                        break;
                                    }
                                }
                                if (do_it) t.status = 'requires_attention';
                            } else if (t.value.length > 0) {
                                t.status = 'requires_attention';
                            }
                        }
                    }
                })
            }

            let saveNewTranslation = (key) => {
                if (key.translations) {
                    let t_index = key.translations.findIndex(t => t.id === translation.id)
                    if (~t_index) {
                        key.translations[t_index] = translation;
                    } else {
                        let tl_index = key.translations.findIndex(t => t.project_language_id === translation.project_language_id);
                        if (~tl_index) key.translations[tl_index] = translation;
                        else key.translations.push(translation);
                    }
                }

                if (update_type) updateMainTranslation(key);
            }

            if (state.detail_key?.translations) {
                let t_index = state.detail_key.translations.findIndex(t => t.id === translation.id);
                if (~t_index) {
                    state.detail_key.translations[t_index] = translation;
                    if (update_type) updateMainTranslation(state.detail_key);
                } else {
                    let tl_index = state.detail_key.translations.findIndex(t => t.project_language_id === translation.project_language_id);
                    if (~tl_index) {
                        state.detail_key.translations[tl_index] = translation;
                        if (update_type) updateMainTranslation(state.detail_key);
                    }
                }
            }

            if (state.keys_list.length) {
                let key = state.keys_list.find(k => k.id === translation.key_id)
                if (key) saveNewTranslation(key)
            }
            if (state.searched_keys.length) {
                let key = state.searched_keys.find(k => k.id === translation.key_id)
                if (key) saveNewTranslation(key)
            }
            if (state.translations_keys_list.length) {
                let key = state.translations_keys_list.find(k => k.id === translation.key_id)
                if (key) saveNewTranslation(key)
            }
        },
        CHANGE_SEARCH_STATUS: (state, boolean) => state.search_status = boolean,
        SAVE_SEARCH_TEXT: (state, text) => state.search_text = text,
        SAVE_SEARCH_PLATFORMS: (state, text) => state.search_platforms = text,
        SAVE_SEARCH_STATUSES: (state, statuses) => state.search_statuses = statuses,
        SAVE_SEARCH_SECTIONS_IDS: (state, sections_ids) => state.search_sections_ids = sections_ids,

        SAVE_TRANSLATION_KEY: (state, key) => state.translation_key = key,
        CHANGE_TRANSLATIONS_KEYS_PARAMS: (state, param) => {
            if (param.clear) state.translations_keys_params = {};
            else if (!param.value) delete state.translations_keys_params[param.name]
            else state.translations_keys_params[param.name] = param.value
        },
        UPDATE_SYSTEM_COMMENT_MODIFIER: (state) => state.system_comment_modifier += 1,
        SAVE_CHANGE_MAIN_TRANSLATE_DATA: (state, data) => state.change_main_translate_data = data,
        TOGGLE_EDIT_PLACEHOLDER_CODE_MODE: (state) => state.edit_placeholder_code_mode = !state.edit_placeholder_code_mode,
        TOGGLE_TRANSLATION_PLACEHOLDER_CODE_MODE: (state) => state.translation_placeholder_code_mode = !state.translation_placeholder_code_mode,
        CHANGE_ACTIVE_TRANSLATION_KEY_ID: (state, id) => state.active_translation_key_id = id,
        SAVE_SEARCHED_KEYS(state, payload) {
            if (payload.clear && !payload.keys) state.searched_keys = []
            else if (payload.clear && payload.keys) state.searched_keys = payload.keys
            else state.searched_keys = state.searched_keys.concat(payload.keys)
        },
        UPDATE_KEYS(state) {
            state.keys_update = true;
        },
        CLEAR_KEYS(state) {
            Object.assign(state, getDefaultState())
        }
    }
}