<template>
  <div class="languages_multiselect"
       v-click-outside="closeList"
       @focusin="focusLanguages"
  >
    <div class="languages_multiselect__item"
         :class="{'active': choose_lang}"
         @click="showList"
    >
      <div class="languages_multiselect__item-info"
           :class="{'active': checked.length}"
      >
        <div class="languages_multiselect__item-info-placeholder">
          {{ placeholder }}
        </div>
        <transition-group
          name="languages-transition"
          tag="div"
          class="languages_multiselect__item-info-name"
        >
          <div v-for="lang in checkedLanguages"
               :key="lang[option_id_name]"
               class="languages_multiselect__item-info-name--item languages-transition-el"
          >
            <w-flag :key="lang" :code="lang.code" class="languages_multiselect__item-info-name--item-flag none-events"/>
            {{ bus.getLanguage(lang.code, 'name') }}
            <div class="languages_multiselect__item-info-name--item-close icon"
                 @click.stop="clearValue(lang[option_id_name])"
            >
              <w-svg-icon folder-name="main" icon-name="iconClose"/>
            </div>
          </div>
        </transition-group>
      </div>
      <template v-if="!no_actions">
        <div class="languages_multiselect__item-arrow icon">
          <w-svg-icon folder-name="profile" icon-name="iconArrowSelect"
                      class="languages_multiselect__item-arrow-svg"
                      :class="{'active': choose_lang}"
          />
        </div>
      </template>
    </div>
    <transition name="select-panel">
      <div v-if="choose_lang" key="languages"
           class="languages_multiselect__list"
           :style="maximum_high ? {maxHeight: max_popup_height + 'px'} : null"
      >
        <div class="languages_multiselect__list-search">
          <label>
            <w-svg-icon class="languages_multiselect__list-search--icon" folder-name="main"
                        icon-name="iconSearch"/>
            <input v-model="query"
                   ref="query"
                   class="languages_multiselect__list-search--input"
                   :placeholder="$t('search')"
                   @input="getTransliterate"
            />
          </label>
        </div>
        <div class="languages_multiselect__list-catalog none-select scrollable hidden_scroll"
             @scroll="scrollView"
        >
          <template v-if="languagesList.length">
            <div v-if="all_languages_btn"
                 key="all"
                 class="languages_multiselect__list-catalog-item"
                 @click="selectAllLanguages"
            >
              <div class="languages_multiselect__list-catalog-item--checked icon"
                   :class="{'active': modelValue.length === computedLanguages.length}">
                <w-svg-icon v-if="modelValue.length === computedLanguages.length"
                            folder-name="main"
                            icon-name="iconOk"/>
              </div>
              <w-flag class="languages_multiselect__list-catalog-item-flag" code="plug"/>
              <div class="languages_multiselect__list-catalog-item-text bold">{{ $t('all_languages') }}</div>
              <div v-if="show_percentage" class="languages_multiselect__list-catalog-item-comment">{{ $t('project_translated_into_languages') }} {{ all_translated_keys_percentage }}%</div>
            </div>
            <label v-for="(lang, i) in languagesList"
                   :key="lang[option_id_name]"
                   :ref="`lang-${i}`"
                   :for="lang[option_id_name]"
                   class="languages_multiselect__list-catalog-item"
            >
              <div class="languages_multiselect__list-catalog-item--checked icon" :class="{'active': checked.includes(lang[option_id_name])}">
                <w-svg-icon v-if="checked.includes(lang[option_id_name])" folder-name="main" icon-name="iconOk"/>
              </div>
              <w-flag class="languages_multiselect__list-catalog-item-flag"
                      :key="lang.code"
                      :code="lang.code"
              />
              <div class="languages_multiselect__list-catalog-item-text">{{ lang.name }}</div>
              <input type="checkbox"
                     class="languages_multiselect__list-catalog-item-input"
                     style="display: none"
                     :value="lang[option_id_name]"
                     :id="lang[option_id_name]"
                     v-model="checked"
                     @change="$emit('update:modelValue', checkedProxy)"
              >
              <div v-if="show_percentage" class="languages_multiselect__list-catalog-item-comment">
                <span v-if="lang.is_main">{{ $t('source_language') }}</span>
                <span v-else :class="{'green': lang.translated_keys_percentage === 100}">{{ lang.translated_keys_percentage }}%</span>
              </div>
            </label>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import textSwitcher from "../../assets/js/text_switcher";
import bus from "@/assets/js/helper.js";

export default {
  name: "w_languages_multiselect",
  props: {
    all_languages_btn: {
      type: Boolean,
      default: false
    },
    hidden_languages: Array,
    languages_list: {
      type: Array
    },
    list_blocked: {
      type: Boolean,
      default: false
    },
    maximum_high: {
      type: Boolean,
      default: false
    },
    modelValue: Array,
    no_actions: {
      type: Boolean,
      default: false
    },
    option_id_name: {
      type: String,
      default: 'id'
    },
    placeholder: {
      type: String
    },
    show_percentage: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      bus: bus,
      query: '',
      transliterate: '',
      max_popup_height: 0,
      choose_lang: false,
      languages_total_count: 20,
      checkedProxy: [],
      top_langs: []
    }
  },
  computed: {
    ...mapGetters(['LOCALE']),
    ...mapState({
      'LANGUAGES': state => state.languages.languages
    }),
    all_translated_keys_percentage() {
      if (!this.show_percentage) return null

      let translation_languages = this.languagesList.filter(l => !l.is_main);

      if (translation_languages.length) {
        let percentage_summ = translation_languages.map(l => l.translated_keys_percentage).reduce((acc, number) => acc + number, 0);
        let result = (Math.floor((percentage_summ / translation_languages.length) * 10) / 10);
        return percentage_summ ? result : 0;
      } else return 0;
    },
    checked: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.checkedProxy = val
      }
    },
    checkedLanguages() {
      return this.computedLanguages.filter(lang => this.checked.includes(lang[this.option_id_name]))
    },
    computedFullList() {
      let array = this.computedLanguages;

      array = array.sort((a, b) => a.name.localeCompare(b.name, this.LOCALE.dictionary_code));

      if (this.hidden_languages) array = array.filter(l => !this.hidden_languages.includes(l[this.option_id_name]));

      if (this.query) {
        array = array
          .filter(l => {
            let l_name = l.name.toLowerCase(),
              query = this.query.toLowerCase(),
              transliterate = this.transliterate.toLowerCase();

            return l_name.includes(query) || l_name.includes(transliterate);
          })
          .sort((a, b) => {
            let a_name = a.name.toLowerCase(),
              b_name = b.name.toLowerCase(),
              q = this.query.toLowerCase(),
              t = this.transliterate.toLowerCase();

            if ((a_name.indexOf(q) < b_name.indexOf(q)) || (a_name.indexOf(t) < b_name.indexOf(t))) return -1
            else if ((a_name.indexOf(q) > b_name.indexOf(q)) || (a_name.indexOf(t) > b_name.indexOf(t))) return 1
            else return 0
          });
      }

      if (this.top_langs.length) {
        array = array
          .sort((a, b) => {
            if (this.top_langs.includes(a[this.option_id_name]) && !this.top_langs.includes(b[this.option_id_name])) return -1
            else if (this.top_langs.includes(b[this.option_id_name]) && !this.top_langs.includes(a[this.option_id_name])) return 1
            else return 0
          });
      }

      return array;
    },
    computedLanguages() {
      return this.languages_list || Array.from(this.LANGUAGES);
    },
    languagesList() {
      return this.computedFullList.slice(0, this.languages_total_count).filter(l => l && l[this.option_id_name]);
    }
  },
  methods: {
    clearValue(lang) {
      this.$emit('update:modelValue', this.checked.filter(l => l !== lang))
    },
    closeList() {
      if (this.choose_lang) this.languages_total_count = 20;
      this.choose_lang = false;
      this.transliterate = this.query = '';
      this.focusLanguages();
    },
    focusLanguages() {
      setTimeout(() => this.$refs['query']?.focus(), 200);
    },
    getTransliterate() {
      this.transliterate = textSwitcher.convert(this.query);
      this.languages_total_count = 20;
    },
    scrollView(e) {
      if (this.languages_total_count < this.computedLanguages.length && (e.target.scrollTop + e.target.clientHeight + 100) >= e.target.scrollHeight) {
        this.languages_total_count += 10;
      }
    },
    selectAllLanguages() {
      if (this.modelValue.length) {
        this.$emit('update:modelValue', []);
      } else {
        this.$emit('update:modelValue', this.computedLanguages.map(l => l[this.option_id_name]));
      }
    },
    showList() {
      if (this.maximum_high && !this.max_popup_height) {
        let page_height = document.body.clientHeight;
        let pos = this.$el.getBoundingClientRect();
        this.max_popup_height = page_height - pos.bottom.toFixed() - 10;
      }

      if (!this.list_blocked) {
        this.choose_lang = !this.choose_lang;
        this.transliterate = this.query = '';
      }

      if (this.choose_lang) this.top_langs = this.modelValue;

      this.focusLanguages();
    }
  },
  emits: ['update:modelValue']
}
</script>

<style lang="scss" scoped>
.languages_multiselect {
  display: flex;
  position: relative;
  min-height: 60px;
  border-radius: 16px;
  background-color: var(--l_input_background);

  &__item {
    position: relative;
    height: 100%;
    min-width: 170px;
    width: 100%;
    z-index: 2;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;
    padding: 0 9px 0 16px;
    background-color: inherit;
    border-radius: inherit;
    transition: background-color 200ms ease, box-shadow 200ms ease;

    &:hover {
      box-shadow: var(--l_input_hover_shadow);
    }

    &.active {
      box-shadow: var(--l_input_active_shadow);
    }

    &-info {
      height: 100%;
      width: calc(100% - 22px);
      min-height: 60px;
      display: flex;
      flex-direction: column;

      &-placeholder {
        position: absolute;
        top: 20px;
        height: 20px;
        font-size: 16px;
        line-height: 20px;
        max-width: 85%;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        transform-origin: top left;
        transition: transform 300ms ease;
        color: var(--w_color_grey6);
      }

      &-languages {
        display: flex;
        flex-wrap: wrap;
      }

      &-text {
        text-overflow: ellipsis;
        overflow: hidden;
        margin-right: auto;
        font-size: 16px;
        line-height: 20px;
      }

      &-name {
        display: flex;
        flex-wrap: wrap;
        margin: 27px 0 10px;
        cursor: pointer;

        &--item {
          display: flex;
          align-items: center;
          padding: 8px 12px;
          background-color: var(--w_color_grey2);
          border-radius: 12px;
          margin: 4px 4px 0 0;
          font-size: 16px;
          line-height: 20px;
          cursor: auto;

          &-flag {
            width: 20px;
            height: 20px;
            min-width: 20px;
            margin-right: 4px;
          }

          &-close {
            width: 12px;
            height: 12px;
            min-width: 12px;
            margin-left: 4px;

            &:deep(path) {
              stroke: var(--w_color_grey6);
            }
          }
        }

        &:first-letter {
          text-transform: capitalize;
        }
      }

      &.active {
        .languages_multiselect__item-info-placeholder {
          transform: scale(0.8) translateY(-15px);
        }
      }

      .languages-transition {
        &-el {
          overflow: hidden;
        }

        &-enter-from, &-leave-to {
          opacity: 0;
          transform: translateX(-10px) scale(0.97);
        }

        &-enter-active, &-leave-active {
          transition-property: transform, opacity, max-width, max-height !important;
          transition-timing-function: ease !important;
          transition-duration: 300ms !important;
          pointer-events: none !important;
        }

        &-leave-active {
          position: absolute !important;
        }

        &-move {
          transition-property: transform, opacity !important;
          transition-timing-function: ease !important;
          transition-duration: 300ms !important;
          pointer-events: none !important;
        }
      }
    }

    &-arrow, &-close {
      height: 100%;
      min-height: 60px;
      width: 32px;
      min-width: 32px;
      pointer-events: auto;
      margin-bottom: auto;

      svg {
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;

        &:deep(path) {
          stroke: var(--w_color_grey6);
        }
      }
    }

    &-arrow {
      pointer-events: none;

      &-svg {
        transition: transform 300ms ease;

        &.active {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__list {
    position: absolute;
    top: calc(100% + 4px);
    width: 100%;
    display: flex;
    flex-direction: column;
    min-width: 265px;
    max-height: 40vh;
    background-color: var(--l_input_background);
    border-radius: 16px;
    padding-bottom: 12px;
    overflow: hidden;
    box-shadow: 0 0 0 1px var(--w_color_grey2), 0 6px 12px rgba(0, 0, 0, 0.08);
    text-align: left;
    transform-origin: top;

    &-search {
      display: flex;
      align-items: center;
      padding: 16px 16px 10px;
      position: -webkit-sticky;
      position: sticky;
      line-height: 14px;
      z-index: 1;
      top: 0;

      label {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        background-color: var(--w_color_grey2);
        padding: 12px 16px;
        border-radius: 12px;
      }

      &--icon {
        width: 16px;
        height: 16px;
        min-width: 16px;
        margin-right: 4px;

        &:deep(path) {
          stroke: var(--w_color_grey6);
        }
      }

      &--input {
        font-size: 14px;
        line-height: 14px;
        padding: 0;
        width: 100%;
      }
    }

    &-catalog {
      &-all {
        text-align: left;

        &-head {
          font-size: 13px;
          line-height: 18px;
          color: var(--w_color_grey3);
          padding: 16px 20px 4px;
        }
      }

      &-item {
        display: flex;
        align-items: center;
        padding: 10px 11px;
        cursor: pointer;
        margin: 0 5px;
        border-radius: 8px;

        &:hover {
          background-color: var(--w_color_grey1);
        }

        &--checked {
          width: 20px;
          height: 20px;
          min-width: 20px;
          background-color: var(--w_color_grey7);
          border-radius: 4px;
          margin-right: 8px;

          &.active {
            background-color: var(--w_color_green) !important;
          }

          svg {
            width: 12px;
            height: 12px;

            &:deep(path) {
              stroke: var(--w_color_white);
            }
          }
        }

        &-flag {
          margin-right: 12px;
          width: 20px;
          height: 20px;
          min-width: 20px !important;
        }

        &-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          &:first-letter {
            text-transform: capitalize;
          }
        }

        &-comment {
          margin-left: auto;
          font-size: 13px;
          line-height: 24px;
          color: var(--w_color_grey6);
        }
      }
    }
  }

  .select-panel {
    &-enter-from, &-leave-to {
      transform: scale(0.95) translateY(-60px);
      max-height: 60px;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);

      .w_select__options-item {
        opacity: 0;
      }
    }

    &-enter-active, &-leave-active {
      transition-property: transform, opacity, max-height, box-shadow;
      transition-duration: 300ms;
      transition-timing-function: ease;
      pointer-events: none !important;
    }
  }
}

@media (max-width: 1440px) and (min-width: 981px) {
  .languages_multiselect {
    min-height: 40px;
    border-radius: 11px;

    &__item {
      min-width: 114px;
      padding: 0 6px 0 11px;

      &-info {
        width: calc(100% - 15px);
        min-height: 40px;

        &-placeholder {
          top: 13px;
          height: 14px;
          font-size: 11px;
          line-height: 14px;
        }

        &-text {
          font-size: 11px;
          line-height: 14px;
        }

        &-name {
          margin: 18px 0 7px;

          &--item {
            padding: 5px 8px;
            border-radius: 8px;
            margin: 3px 3px 0 0;
            font-size: 11px;
            line-height: 14px;

            &-flag {
              width: 14px;
              height: 14px;
              min-width: 14px;
              margin-right: 3px;
            }

            &-close {
              width: 8px;
              height: 8px;
              min-width: 8px;
              margin-left: 3px;
            }
          }
        }

        &.active {
          .languages_multiselect__item-info-placeholder {
            transform: scale(0.8) translateY(-10px);
          }
        }

        .languages-transition {
          &-enter-from, &-leave-to {
            transform: translateX(-7px) scale(0.97);
          }
        }
      }

      &-arrow, &-close {
        min-height: 40px;
        width: 22px;
        min-width: 22px;

        svg {
          width: 16px;
          height: 16px;
          min-width: 16px;
          min-height: 16px;
        }
      }
    }

    &__list {
      top: calc(100% + 3px);
      min-width: 176px;
      border-radius: 11px;
      padding-bottom: 8px;

      &-search {
        padding: 11px 11px 7px;
        line-height: 9px;

        label {
          padding: 8px 11px;
          border-radius: 8px;
        }

        &--icon {
          width: 11px;
          height: 11px;
          min-width: 11px;
          margin-right: 3px;
        }

        &--input {
          font-size: 9px;
          line-height: 9px;
        }
      }

      &-catalog {
        &-all-head {
          font-size: 8px;
          line-height: 12px;
          padding: 11px 14px 3px;
        }

        &-item {
          padding: 7px 8px;
          margin: 0 3px;
          border-radius: 5px;

          &--checked {
            width: 14px;
            height: 14px;
            min-width: 14px;
            border-radius: 3px;
            margin-right: 5px;

            svg {
              width: 8px;
              height: 8px;
            }
          }

          &-flag {
            margin-right: 8px;
            width: 14px;
            height: 14px;
            min-width: 14px !important;
          }

          &-comment {
            font-size: 8px;
            line-height: 16px;
          }
        }
      }
    }

    .select-panel {
      &-enter-from, &-leave-to {
        transform: scale(0.95) translateY(-40px);
        max-height: 40px;
      }
    }
  }
}

@media screen and (max-width: 980px) {

}

[data-theme='dark'] {
  .languages_multiselect {

    &__item-info-name--item {
      background-color: var(--w_color_grey4);
    }

    &__list {
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

      &-search label {
        background-color: var(--w_color_grey5);
      }

      &-catalog-item {
        &:hover {
          background-color: var(--w_color_grey4);
        }

        &--checked {
          background-color: var(--w_color_grey5);
        }
      }
    }

    .select-panel {
      &-enter-from, &-leave-to {
        box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
      }
    }
  }
}
</style>
