<template>
    <div class="w_textarea"
         :class="{'active': inputFocus}"
         :style="style"
         @click="focusTextarea"
    >
        <label class="w_textarea__label"
               :class="{'active': !hidden_placeholder && (inputFocus || modelValue), 'alight-center': hidden_placeholder}"
        >
            <div v-if="hidden_placeholder ? (!modelValue) : true"
                 class="w_textarea__label-name"
            >
                <w-flag v-if="mini_flag" class="w_textarea__label-name-flag" :code="mini_flag"/>
                <span class="w_textarea__label-name-text">{{ placeholder }}</span>
            </div>
            <div v-if="readonly" v-html="modelValue"
                 class="w_textarea__label-text scrollable"
                 ref="w_textarea"
            />
            <translation_editor
                v-else-if="contenteditable"
                :model-value="modelValue"
                :readonly="readonly"
                :maxlength="maxlength"
                :autofocus="autofocus"
                code_mode
                class="scrollable"
                ref="translation_editor"
                @focusin="inputFocus = true"
                @focusout="inputFocus = false"
                @input="inputText"
                @handleEnter="handleEnter"
            />
            <textarea v-else
                      class="w_textarea__label-text scrollable"
                      :style="computedStyles"
                      :spellcheck="spellcheck"
                      :value="modelValue"
                      :maxlength="maxlength"
                      :rows="rows"
                      :readonly="readonly"
                      ref="w_textarea"
                      @input="inputText($event.target.value)"
                      @change="inputText($event.target.value)"
                      @focusin="inputFocus = true"
                      @focusout="inputFocus = false"
                      @focus="resize"
                      @contextmenu="contextAction"
                      @keydown.enter="handleEnter"
            />
        </label>
        <div class="w_textarea__detail" v-if="detail">
            <w-flag v-if="flag" class="w_textarea__detail-flag" :code="flag"/>
            <slot v-else name="detail"/>
        </div>
    </div>
</template>

<script>
import translation_editor from "@/components/elements/detail_key/translation_editor.vue";

export default {
    name: "w_textarea",
    components: {translation_editor},
    props: {
        autofocus: {
            type: [String, Number],
            default: 0
        },
        autosize: {
            type: Boolean,
            default: true
        },
        contenteditable: {
            type: Boolean,
            default: false
        },
        contenteditable_id: {
            type: String
        },
        detail: {
            type: Boolean,
            default: false
        },
        flag: String,
        hidden_placeholder: {
            type: Boolean,
            default: false
        },
        important: {
            type: [Boolean, Array],
            default: false
        },
        maxHeight: {
            type: Number,
            default: 120
        },
        maxlength: {
            type: [Number, String],
            default: 255
        },
        minHeight: {
            type: Number,
            default: null
        },
        mini_flag: String,
        modelValue: String,
        placeholder: {
            type: String,
            default: ''
        },
        pointerEvents: {
            type: String,
            default: 'auto'
        },
        readonly: {
            type: Boolean,
            default: false
        },
        regexp: RegExp,
        rows: {
            type: [String, Number],
            default: 1
        },
        spellcheck: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            inputFocus: false,
            maxHeightScroll: false,
            current_position: 0,
            height: 'auto'
        }
    },
    computed: {
        computedStyles() {
            if (!this.autosize) return {}
            return {
                resize: !this.isResizeImportant ? 'none' : 'none !important',
                height: this.height,
                overflow: this.maxHeightScroll ? 'auto' : (!this.isOverflowImportant ? 'hidden' : 'hidden !important')
            }
        },
        isHeightImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('height'))
        },
        isOverflowImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('overflow'))
        },
        isResizeImportant() {
            const imp = this.important
            return imp === true || (Array.isArray(imp) && imp.includes('resize'))
        },
        style() {
            return {
                pointerEvents: this.pointerEvents
            }
        }
    },
    watch: {
        autosize(val) {
            if (val) this.resize()
        },
        maxHeight() {
            this.$nextTick(this.resize)
        },
        minHeight() {
            this.$nextTick(this.resize)
        },
        modelValue(val) {
            this.$nextTick(this.resize);
            this.inputText(val);
        }
    },
    mounted() {
        this.resize()

        if (this.autofocus !== 'off') {
            let duration = Number(this.autofocus)
            if (duration > 0) setTimeout(() => this.$el.querySelector('textarea').focus(), duration)
        }
    },
    methods: {
        contextAction(e) {
            if (this.spellcheck) e.stopPropagation()
        },
        focusTextarea() {
            if (this.contenteditable) {
                if (!this.inputFocus) {
                    this.$refs.translation_editor?.setFocus();
                    this.$refs.translation_editor?.setCursor(true);
                }
            }
        },
        handleEnter(e) {
            if (!this.modelValue) e.preventDefault();
            else if (e.shiftKey == false) {
                this.$emit('handleEnter');
                e.preventDefault();
            }
        },
        inputText(text) {
            let result = text;
            if (this.regexp) result = this.validateText(result);
            if (this.contenteditable && !text.length) this.$refs.translation_editor?.setValue('');
            this.$emit('update:modelValue', result);
        },
        resize() {
            this.height = `auto${this.isHeightImportant ? ' !important' : ''}`
            this.$nextTick(() => {
                let contentHeight = this.$refs['w_textarea']?.scrollHeight
                if (this.minHeight) {
                    contentHeight = contentHeight < this.minHeight ? this.minHeight : contentHeight
                }
                if (this.maxHeight) {
                    if (contentHeight > this.maxHeight) {
                        contentHeight = this.maxHeight
                        this.maxHeightScroll = true
                    } else {
                        this.maxHeightScroll = false
                    }
                }
                const heightVal = contentHeight + 'px'
                this.height = `${heightVal}${this.isHeightImportant ? ' !important' : ''}`
            })
            return this
        },
        validateText(text) {
            if (this.regexp) {
                let result = "";
                if (text.match(this.regexp)) result = text.replaceAll(this.regexp, '');
                return result
            }
        }
    },
    emits: ['update:modelValue', 'handleEnter']
}
</script>

<style lang="scss" scoped>
.w_textarea {
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    width: 100%;
    box-shadow: var(--l_input_start_shadow);
    background-color: var(--l_input_background);
    color: var(--w_color_dark);
    transition: box-shadow 200ms ease;

    &:hover {
        box-shadow: var(--l_input_hover_shadow) !important;
    }

    &.active {
        box-shadow: var(--l_input_active_shadow) !important;
    }

    &__detail {
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        &-flag {
            width: 24px;
            height: 24px;
            min-width: 24px !important;
        }
    }

    &__label {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 30px 16px 8px;
        text-align: left;
        position: relative;
        color: inherit;
        transition: all .3s linear;
        cursor: text;

        &-name, &-text {
            font-size: 16px;
            line-height: 22px;
            padding: 0;
            background-color: transparent;
        }

        &-name {
            max-width: 90%;
            position: absolute;
            top: 19px;
            left: 16px;
            transition: transform 300ms ease;
            pointer-events: none;
            transform-origin: top left;
            display: flex;
            align-items: center;
            user-select: none;

            &-flag {
                width: 20px;
                height: 20px;
                margin-right: 4px;
                border: 1px solid var(--w_color_grey7);
                box-sizing: border-box !important;
            }

            &-text {
                color: var(--w_color_grey6);
            }
        }

        &-text {
            background-color: transparent;
            color: inherit;
            caret-color: var(--w_color_green);
            transform: translateY(-6px);
            transition: transform 300ms ease;

            &:deep *:not(span[data-placeholder]) {
                margin: 0 !important;
                color: var(--w_color_dark) !important;
                background-color: transparent !important;
            }
        }

        &.alight-center {
            padding: 13px 16px;

            .w_textarea__label-text {
                transform: none !important;
            }
        }

        &.active {
            .w_textarea__label-name {
                transform: scale(0.8) translateY(-12px);
            }

            .w_textarea__label-text {
                transform: none;
            }
        }

        &-text.editable:not(:empty) {
            transform: none;

            .w_textarea__label-name {
                transform: scale(0.8) translateY(-12px);
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .w_textarea {
        border-radius: 11px;

        &__detail {
            height: 40px;
            width: 40px;

            &-flag {
                width: 16px;
                height: 16px;
                min-width: 16px !important;
            }
        }

        &__label {
            padding: 20px 11px 5px;

            &-name, &-text {
                font-size: 11px;
                line-height: 15px;
            }

            &-name {
                top: 13px;
                left: 11px;

                &-flag {
                    width: 14px;
                    height: 14px;
                    margin-right: 3px;
                    margin-bottom: 1px;
                }
            }

            &-text {
                transform: translateY(-4px);
            }

            &.alight-center {
                padding: 8px 11px;
            }

            &.active .w_textarea__label-name {
                transform: scale(0.8) translateY(-8px);
            }

            &-text.editable:not(:empty) .w_textarea__label-name {
                transform: scale(0.8) translateY(-8px);
            }
        }
    }
}

[data-theme='dark'] {
    .w_textarea__label-name-flag {
        border: 1px solid var(--w_color_grey2);
    }
}
</style>
