<template>
    <transition name="loading-bounce">
        <div v-if="SITE_LOAD" class="site_loading icon">
            <w-svg-icon class="site_loading-icon none-events" folder-name="main" icon-name="iconLogo"/>
        </div>
    </transition>
    <router-view v-slot="{ Component }" class="view">
        <transition :name="$page_transition.name" :mode="$page_transition.mode">
            <component :is="Component"/>
        </transition>
    </router-view>

    <w-alert :show="Boolean(ERROR_SYSTEM)" error>
        <template v-if="$te(ERROR_SYSTEM)">{{ $t(ERROR_SYSTEM) }}</template>
        <template v-else>{{ ERROR_SYSTEM }}</template>
    </w-alert>

    <transition name="modal">
        <popup-server-error v-if="ERROR_SERVER"/>
        <popup-internet-connection v-else-if="show_internet_connection_popup" @closePopup="show_internet_connection_popup = false"/>
        <popup-not-all-placeholders-be-used v-else-if="INPUT_ALERT_POPUP"/>
    </transition>

    <site_theme/>

    <transition name="modal-image">
        <popupImage v-if="IMAGE_POPUP" key="images" :object_data="IMAGE_POPUP"/>
        <popupImageEditor v-else-if="IMAGE_EDITOR_POPUP" key="image_editor" :image_data="IMAGE_EDITOR_POPUP"/>
    </transition>

    <transition name="notifications-fade">
        <notifications_list v-if="NOTIFICATIONS_PANEL"/>
    </transition>

    <transition v-if="$store.state.other.development_host && !MOBILE && $api_url_chat" name="bounce">
        <chat-component v-if="isLoggedIn"/>
    </transition>

    <prod_switcher v-if="$store.state.other.development_host && !MOBILE"/>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {defineAsyncComponent} from "vue";
import bus from "@/assets/js/helper.js";
import isMobile from 'ismobilejs';
import popupInternetConnection from "@/components/popups/popupInternetConnection.vue";
import site_theme from "@/components/elements/site_theme.vue";
import SUPPORTED_LOCALES from "../supported_locales.js";
import Cookies from "js-cookie";

let click_count = 0;

export default {
    name: 'App',
    components: {
        site_theme,
        popupInternetConnection,
        notifications_list: defineAsyncComponent(() => import("@/components/notifications_list.vue")),
        popupServerError: defineAsyncComponent(() => import("@/components/popups/popupServerError.vue")),
        popupImage: defineAsyncComponent(() => import("@/components/popups/popupImage.vue")),
        popupImageEditor: defineAsyncComponent(() => import("@/components/popups/popupImageEditor.vue")),
        popupNotAllPlaceholdersBeUsed: defineAsyncComponent(() => import("@/components/popups/popupNotAllPlaceholdersBeUsed.vue")),
        ChatComponent: defineAsyncComponent(() => import("@/components/chat.vue")),
        prod_switcher: defineAsyncComponent(() => import("@/components/prod_switcher.vue")),
    },
    data() {
        return {
            show_internet_connection_popup: false,
            fullScreenPagesTopLevel: [
                'AdditionKeyPage',
                'EditionKeyPage',
                'DetailKeyPage',
                'CreateNewProjectPage',
                'CreateNewInvitation',
                'EditMember',
                'ProjectDownloadMode',
                'ProjectUploadMode',
                'ProjectOrders',
                'ProjectOrderTranslation',
                'ProjectSettingsPage',
                'ProfileSettingsPage',
            ],
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'USER_LOCALE', 'LOCALE', 'SELECTED_TEAM', 'MOBILE']),
        ...mapState({
            "ERROR_SYSTEM": state => state.errors.error_system,
            "ERROR_SERVER": state => state.errors.error_server,
            "INTERNET_CONNECTION": state => state.other.internet_connection,
            "IMAGE_POPUP": state => state.popups.image_popup,
            "IMAGE_EDITOR_POPUP": state => state.popups.image_editor_popup,
            "INPUT_ALERT_POPUP": state => state.popups.input_alert_popup,
            "DELETED_KEY_IDS": state => state.cache.deleted_key_ids,
            "NOTIFICATIONS_PANEL": state => state.notifications.notifications_panel,
            "TEAMS_LIST": state => state.teams.teams_list,
            "PAGE_KEY": state => state.other.page_key,
            "SITE_LOAD": state => state.other.site_load
        }),
        fullScreenPagesAll() {
            return this.fullScreenPagesMiddle.concat([
                'CreateNewProjectPage',
                'CreateNewInvitation',
                'ApproveInviteToTeamPage',
            ])
        },
        fullScreenPagesMiddle() {
            return this.fullScreenPagesTopLevel.concat([
                'ProjectPage',
                'TeamProjectsPage',
                'TeamCrewPage',
            ])
        },
    },
    watch: {
        $route: {
            handler(to, from) {
                if (!from || (from.params.team_uuid && (from.params.team_uuid !== to.params.team_uuid))) {
                    let team_uuid = to.params.team_uuid;

                    if (!team_uuid) team_uuid = location.href.match(/(?<team>\/team\/)(?<uuid>[a-zA-Z0-9-]*)/gs)?.[0]?.replace('/team/', '');

                    if (team_uuid) {
                        let team = this.TEAMS_LIST.find(t => t.uuid === team_uuid);

                        if (team) {
                            this.$store.dispatch("CHANGE_SELECTED_TEAM", team);
                            this.$store.dispatch("GET_PROJECTS_LIST");
                            this.$store.dispatch("GET_CREW_LIST", "update");
                        }
                    }
                }

                if (from && this.fullScreenPagesAll.includes(from.name) && this.fullScreenPagesAll.includes(to.name)) {
                    let front_type = from.fullPath.split('/').length < to.fullPath.split('/').length;
                    if (this.fullScreenPagesTopLevel.includes(to.name) && this.fullScreenPagesAll.includes(from.name)) front_type = true;
                    else if (this.fullScreenPagesTopLevel.includes(from.name) && this.fullScreenPagesAll.includes(to.name)) front_type = false;
                    else if (this.fullScreenPagesTopLevel.includes(to.name)) front_type = true;

                    this.$page_transition.setParams(front_type ? 'page-bounce-front' : 'page-bounce-back', 'in-out');
                } else {
                    this.$page_transition.setParams('bounce', 'out-in');
                }
            },
            immediate: true
        }
    },
    created() {
        this.saveDeviceWidth();

        let locale, locales = SUPPORTED_LOCALES.map(l => l.code);

        if (this.USER_LOCALE.code) {
            locale = this.USER_LOCALE.code;
        } else {
            let params = location.pathname.split('/');
            if (locales.includes(params[1])) locale = params[1];
        }

        if (!locale) locale = SUPPORTED_LOCALES[0].code; // en locale

        bus.changeLocal(locale, true);
    },
    mounted() {
        this.startApp();
        document.dispatchEvent(new Event('render-event'));
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);

        let user_locale = Cookies.get('cookie_localize')
        if (!user_locale) window.addEventListener('click', this.setUserLocale);
    },
    methods: {
        saveDeviceWidth() {
            this.$store.commit("SAVE_IS_MOBILE", isMobile().any);

            let root = document.documentElement,
                dataTheme = root.getAttribute('data-theme'),
                storageTheme = localStorage.getItem('user_theme');

            if (!storageTheme) {
                const
                    isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
                    prefersColorScheme = window.getComputedStyle(document.body).getPropertyValue('--w_wola_theme');

                if (prefersColorScheme) {
                    root.setAttribute('data-theme', prefersColorScheme);
                    this.toggleTheme(prefersColorScheme);
                } else if (isDarkMode) {
                    root.setAttribute('data-theme', 'dark');
                    this.toggleTheme('dark');
                }
            } else if (storageTheme && (dataTheme !== storageTheme)) {
                root.setAttribute('data-theme', storageTheme);
                this.toggleTheme(storageTheme);
            }
        },
        setUserLocale() {
            click_count += 1;

            if (click_count > 2) {
                let user_locale = SUPPORTED_LOCALES.find(l => l.code === this.$route.params.locale || 'en');
                this.$store.commit("SAVE_USER_LOCALE", user_locale);
                Cookies.set('cookie_localize', user_locale.code, {secure: true, expires: Date.now() + Number(1577e+10)});
                window.removeEventListener('click', this.setUserLocale);
            }
        },
        spreadAcrossScreens(count) {
            if (count === 3) {
                const invite_to_team = /(invite-to-team)/.test(location.pathname)
                const password_reset = /(password-reset)/.test(location.pathname)
                const privacy_pages = /(privacy)|(terms)|(public_offer)/.test(location.pathname)

                if (!invite_to_team && !privacy_pages) {
                    if (this.isLoggedIn) {
                        if (!this.TEAMS_LIST.length) {
                            if (this.$route.name !== 'PromoPage') {
                                this.$router.push({name: 'CreateNewTeamPage'})
                            }
                        }
                    } else if (!password_reset) {
                        let c1 = this.$route.name ? (this.$route.name !== 'PromoPage') : false;
                        let c2 = !/^\/(.{2})?\/?$/.test(location.pathname);
                        if (c1 || c2) this.$router.push({name: 'Auth'});
                    }
                }

                this.$store.commit("LOAD_SITE", false);
            }
        },
        startApp() {
            window.onresize = () => this.saveDeviceWidth();

            let c = 0;

            this.$store.dispatch("LOAD_LANGUAGES").finally(() => this.spreadAcrossScreens(++c));

            if (this.isLoggedIn) {
                this.$store.dispatch("LOAD_USER_INFO").finally(() => this.spreadAcrossScreens(++c));
                this.$store.dispatch("GET_TEAMS_LIST").finally(() => this.spreadAcrossScreens(++c));
                this.$store.dispatch("LOAD_NOTIFICATIONS_UNREAD_AVAILABLE");
                if (this.DELETED_KEY_IDS.length) this.DELETED_KEY_IDS.forEach(id => this.$store.dispatch("DELETE_KEY", id));
            } else this.spreadAcrossScreens(c += 2)
        },
        toggleTheme(theme) {
            this.$store.commit("CHANGE_SITE_THEME_PREFERS", {animation: true, theme: theme});
        },
        async updateOnlineStatus() {
            this.show_internet_connection_popup = !navigator.onLine;
            this.$store.commit("SAVE_INTERNET_CONNECTION", navigator.onLine);
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.site_loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: var(--w_color_grey2);

    &-icon {
        width: 120px;
        height: 120px;
        animation: blinking 2s infinite;

        @keyframes blinking {
            70% {
                opacity: 1;
                transform: scale(1.05);
            }
            0%, 20%, 70%, 100% {
                opacity: 1;
            }
            50% {
                opacity: 0;
                transform: scale(0.9);
            }
        }
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .site_loading-icon {
        width: 80px;
        height: 80px;
    }
}
</style>
