import store from "@/store";
import router from "@/router";
import {encode} from 'html-entities';
import i18n from "@/plugins/i18n";
import SUPPORTED_LOCALES from "@/../supported_locales.js";
import Cookies from "js-cookie";
import {getPlaceholderName} from "@/assets/js/parsing_settings.js";
import platform from "@/assets/js/platform";

let EmojiConvertor = require('emoji-js');
let emoji = new EmojiConvertor();

// wola_f = ["[%s]", "[%1$s]", "[%s:name]", "[%d]", "[%1$d]", "[%d:name]", "[%f]", "[%.2f]", "[%1$.2f]", "[%.2f:name]"];
// printf_f = ["%s", "%1$s", "%d", "%1$d", "%f", "%.2f", "%1$.2f"];
// ios_f = ["%@", "%1$@", "%li", "%1$li", "%f", "%.2f", "%1$.2f"];
// icu_f = ["{0}", "{name}"];
// net_f = ["{0}", "{0:0.00}"];
// symfony_f = ["%placeholder_1%", "%name%"];
// i18n_f = ["{{0}}", "{{name}}"];
// raw_f = ["[%s]", "[%1$s]", "[%s:name]", "[%i]", "[%1$i]", "[%i:name]", "[%f]", "[%.2f]", "[%1$.2f]", "[%.2f:name]"];

const regex1 = '\\[%[a-zA-Z0-9:._$]{1,}]';
const regex2 = '{{1,2}[a-zA-Z0-9:._]{1,}}{1,2}';
const regex3 = '%[a-zA-Z0-9_.$@]{1,}%?';
const openTag = '<[a-z]*(\\s)?[a-z"\'=\\s:/.-]*>';
const closeTag = '<\\/[a-z]*>';

const placeholdersRegExp = new RegExp(`(${regex1})|(${regex2})|(${regex3})`, 'g');
const tagRegExp = new RegExp(`(${openTag})|(${closeTag})`, 'g');
const fullRegExp = new RegExp(`(${regex1})|(${regex2})|(${regex3})|(${openTag})|(${closeTag})`, 'g');

let cached_gt_text = new Map();
let cached_placeholders_array = new Map();

let actual_title = '';
let actual_desc = '';

export default {
    async changeLocal(lang, start) {
        let stateLang = SUPPORTED_LOCALES.find(loc => loc.code === lang);

        if (start) {
            store.commit("SAVE_LOCALE", stateLang)
        } else {
            store.commit('SAVE_USER_LOCALE', stateLang);
            Cookies.set('cookie_localize', stateLang.code, {secure: true, expires: Date.now() + Number(1577e+10)});
        }

        import(`@/locales/${stateLang.code}.json`)
            .then(messages => {
                i18n.global.setLocaleMessage(stateLang.code, messages || {});
                i18n.global.locale = stateLang.code;
                this.updateTitles()
                if (!start) router.replace({params: {locale: stateLang.code}});
            })
            .catch(err => console.error('Localit:', err))
    },
    updateTitles(to) {
        let title = "Localit", desc = i18n.global.t('about_meta_desc');

        if (to && to.meta?.title) actual_title = to.meta.title;
        if (to && to.meta?.description) actual_desc = to.meta.description;

        if (actual_title) {
            if (!i18n.global.te(actual_title)) title = actual_title;
            else title = i18n.global.t(actual_title);
        }
        if (actual_desc && i18n.global.te(actual_desc)) {
            desc = i18n.global.t(actual_desc);
        }

        document.title = title;
        document.querySelector('meta[name="description"]').setAttribute("content", desc);
    },
    compressImage(image) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            let fileName = image.name, format = fileName.match(/\.([^.]+)$|$/)?.[1];
            if (!['jpg', 'jpeg'].includes(format)) fileName = fileName.replaceAll(format, 'jpg');

            reader.readAsDataURL(image);
            reader.onload = (event) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = async () => {
                    const
                        c = document.createElement('canvas'),
                        width = img.naturalWidth,
                        height = img.naturalHeight,
                        max_size = 1280;

                    if ((width > max_size) || (height > max_size)) {
                        if (width > height) {
                            c.width = max_size;
                            c.height = max_size * height / width;
                        } else if (width < height) {
                            c.width = max_size * width / height;
                            c.height = max_size;
                        } else {
                            c.width = max_size;
                            c.height = max_size;
                        }
                    } else {
                        c.width = width;
                        c.height = height;
                    }

                    const ctx = c.getContext('2d');
                    ctx.drawImage(img, 0, 0, c.width, c.height);
                    ctx.canvas.toBlob(async (blob) => {
                        let file_image = new File([blob], fileName, {
                            type: 'image/jpeg',
                            lastModified: Date.now()
                        });
                        resolve({file_url: URL.createObjectURL(blob), file_image});
                    }, 'image/jpeg', 0.7);
                };
                reader.onerror = error => {
                    reject(error);
                    console.error('Localit:', error);
                    store.dispatch("ERROR_SHOW", {name: 'system', content: 'server_error_title'})
                };
            };
        })
    },
    copyLink(text) {
        return new Promise(resolve => {
            let input = document.createElement("input");
            input.value = text

            if (navigator.clipboard) {
                navigator.clipboard
                    .writeText(input.value)
                    .then(() => share(input.value))
                    .catch(err => console.info('Localit: Something went wrong', err));
            } else {
                input.style.display = 'block'
                input.select()
                document.execCommand("copy")
                input.style.display = 'none'
                share(input.value)
            }

            let share = (str) => {
                if (store.getters.MOBILE && !!navigator.share) {
                    navigator.share({url: str})
                }
                resolve()
            }
        })
    },
    getMsg(text, parse, no_link, icon) {
        let message = ''

        if (text) {
            if (!parse) message = text.replaceAll(/</g, "&lt;").replaceAll(/>/g, "&gt;");
            else message = text;
            message = platform.platform.isApple ? message : emoji.replace_unified(message);
            // if (icon) message = message.slice(0, -1) + ` alt="${text}" ` + message.slice(-1)
            if (!no_link && !icon) message = this.urlify(message);
        }

        return message
    },
    isTagRegex(text) {
        return text.match(tagRegExp)?.length > 0;
    },
    formatBytes(size) {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return parseFloat((size / Math.pow(1024, i)).toFixed(1)) + ' ' + ['B', 'KB', 'MB'][i];
    },
    getNumericalFormat(n, type) {
        let f = [];

        if (type === 'age') f = ['age_format_1', 'age_format_2', 'age_format_3', 'age_format_4'];
        else if (type === 'word') f = ['word_format_1', 'word_format_2', 'word_format_3', 'word_format_4'];
        else if (type === 'sections') f = ['sections_format_1', 'sections_format_2', 'sections_format_3', 'sections_format_4'];
        else if (type === 'keys') f = ['keys_format_1', 'keys_format_2', 'keys_format_3', 'keys_format_4'];
        else if (type === 'project_languages') f = ['project_languages_format_1', 'project_languages_format_2', 'project_languages_format_3', 'project_languages_format_4'];

        return f[(n > 9 && /1$/.test(n)) ? 3 : (n % 100 > 4 && n % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(n % 10 < 5) ? n % 10 : 5]];
    },
    getLanguage(lang_id, key) {
        if (!lang_id) return '';

        let language;
        try {
            language = store.state.languages.languages.find(lang => (lang.id === lang_id) || (lang.code === lang_id));
        } catch (e) {
            language = lang_id;
        }

        if (language) {
            if (key) return language[key];
            else return language;
        } else return null;
    },
    getPlaceholders(text) {
        if (!text) return [];
        else if (cached_placeholders_array.has(text)) return Array.from(cached_placeholders_array.get(text));

        let result = text.match(fullRegExp) || [],
            i = 0,
            ti_o = 1,
            ti_c = 1,
            pi = 1;

        result = result.map(p => {
            const t = {value: p, i: i++};

            if (p.match(placeholdersRegExp)?.length) {
                Object.assign(t, {
                    placeholder: p,
                    index: pi,
                    name: getPlaceholderName(p),
                    contenteditable: this.code_mode
                })
                pi++;
            } else {
                let is_closed = p.startsWith('</');
                let is_alone = p.endsWith('/>');

                Object.assign(t, {
                    placeholder: p,
                    index: is_closed ? ti_c : ti_o,
                    name: '',
                    contenteditable: this.code_mode
                })

                if (!is_alone) t['position'] = is_closed ? 'end' : 'start';

                if (is_alone || is_closed) {
                    ti_c--;
                } else {
                    ti_c = ti_o;
                    ti_o++;
                }
            }

            return t;
        })

        cached_placeholders_array.set(text, result);
        return result;
    },
    convertTextForGoogleTranslate(text) {
        if (!text) return '';
        else if (cached_gt_text.has(text)) return cached_gt_text.get(text)

        let result = encode(text).replaceAll(placeholdersRegExp, m => `<span class="notranslate">${m}</span>`)

        cached_gt_text.set(text, result);
        return result;
    },
    selectNicknames(text) {
        let message = ''
        if (text) {
            let new_line = ' ⇝' + Math.random().toString(36).substring(2, 10) + ' ';

            message = encode(text)
                .replaceAll('\n', new_line)
                .split(' ')
                .map(word => /^@[a-z]*/i.test(word) ? `<span class="nickname">${word}</span>` : word)
                .join(' ')
                .replaceAll(new_line, '\n')
        }
        return message
    },
    sortedPluralValues(values = {}) {
        const order = ['zero', 'one', 'two', 'few', 'many', 'other'];
        return Object.fromEntries(Object.entries(values).sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0])));
    },
    urlify(text) {
        let urlRegex = /(https?:\/\/[^\s]+)/g;

        return text.replace(urlRegex, href => {
            let new_url = new URL(href),
                decoded = decodeURI(new_url.href);

            if (location.host === new_url.host) {
                let has_id = new_url.searchParams.get('translation_id')

                if (has_id && has_id.length) {
                    has_id = has_id.replace(/\D+/g, "")

                    let new_href = href.replace(/[\s.,]/g, ''),
                        word = new_url.searchParams.get('search');

                    return `<a href="${new_href}" target="_blank" class="word" data-id="${has_id}">${decodeURI(word)}</a>`
                }
                return `<a href="${href}" target="_blank">${decodeURI(decoded)}</a>`
            } else {
                return `<a href="${href}" target="_blank">${decodeURI(decoded)}</a>`
            }
        })
    }
}
