import axios from "axios";
import Vue from "vue";
import moment from 'moment';

let search_timeout, search_update = false;

const getDefaultState = () => {
    return {
        centrifuge: null,
        companion_uuid: null,
        chat_id: null,
        chat_new_companion_uuid: null,
        chats_list: [],
        chats_list_all_loaded: false,
        chats_load_limit: 12,
        new_chat: {},
        delete_chat: {},
        messages_list: [],
        messages_list_all_loaded: false,
        messages_load_limit: 30,
        new_companions_load_limit: 15,
        new_companions_list: [],
        new_companions_refresh: true,
        unread_messages_status: false,
        found_messages: [],
        found_messages_empty: false,
        show_companions_page: false,
        select_messages: [],
        select_messages_status: false,
        forward_messages_status: false,
        forward_messages_popup_status: false,
        opened_image_retry: false,
        opened_image_info: {},
        filter_geo: {
            country: {},
            city: {},
        }
    }
}

export default {
    state: () => getDefaultState(),
    getters: {
        SELECTED_CHAT: state => {
            let chat = state.chats_list.find(chat => chat.id === state.chat_id)

            if (chat) return chat
            else if (state.chat_id === state.new_chat.id) return state.new_chat
            else return {}
        },
        SELECTED_CHAT_USER: (state, getters) => getters.SELECTED_CHAT.users ? getters.SELECTED_CHAT.users[0] : null
    },
    actions: {
        CLOSE_CHAT({commit}) {
            commit("SAVE_MESSAGES_LIST", {
                "type": "update",
                "messages": []
            })
            commit("SAVE_COMPANION_UUID", null)
            commit("SAVE_CHAT_ID", null)
        },
        GET_CHATS_LIST({state, getters, commit}, type) {
            moment.locale(getters.LOCALE.code);

            return new Promise((resolve, reject) => {
                let URL = this.$api_url_chat + '/chat/list?limit=' + state.chats_load_limit

                if (type === 'load_up') {
                    let last_chat = state.chats_list[state.chats_list.length - 1]
                    URL += '&last_message_id=' + last_chat.last_message.id
                } else {
                    commit("CHANGE_CHATS_LIST_ALL_LOADED", false)
                }

                axios
                    .get(URL)
                    .then((resp) => {
                        let chats = resp.data.data.chats
                        if (type === 'load_up') commit("CONCAT_CHATS_LIST", chats)
                        else commit("SAVE_CHATS_LIST", chats)

                        if (chats.length < state.chats_load_limit) commit("CHANGE_CHATS_LIST_ALL_LOADED", true)

                        resolve()
                    })
                    .catch(e => reject(e))
            })
        },
        GET_CHAT({state}, id) {
            return new Promise((resolve, reject) => {
                state
                axios
                    .get(this.$api_url_chat + '/chat/' + id + '/info')
                    .then((resp) => resolve(resp.data.data.chat))
                    .catch((error) => reject(error))
            })
        },
        UPDATE_CHAT({state, dispatch}, message) {
            let chatIndex = state.chats_list.findIndex(chat => chat.id === message.chat_id),
                sortChats = () => {
                    state.chats_list = state.chats_list.sort((a, b) => {
                        if (a.last_message.received_at < b.last_message.received_at) return 1
                        else if (a.last_message.received_at > b.last_message.received_at) return -1
                        else return 0
                    })
                };

            if (~chatIndex) {
                state.chats_list[chatIndex]['last_message'] = message
                if ('unread_messages_count' in message) state.chats_list[chatIndex]['unread_messages_count'] = message.unread_messages_count
                sortChats()
            } else {
                dispatch("GET_CHAT", message.chat_id)
                    .then(chat => {
                        state.chats_list.unshift(chat)
                        sortChats()
                    })
            }
        },
        DELETE_CHAT({commit}, ids) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url_chat + '/chat/hide', {chat_ids: ids})
                    .then(() => {
                        commit('DELETE_CHATS_FROM_LIST', ids)
                        resolve()
                    })
                    .catch(e => reject(e))
            })
        },
        GET_CHAT_MESSAGES({state, getters, commit}, request) {
            let id;

            if (request.chat_id) id = '/' + request.chat_id + '?'
            else id = '?recipient_uuid=' + request.uuid + '&'

            let URL = this.$api_url_chat + '/chat' + id + 'limit=' + state.messages_load_limit

            return new Promise((resolve, reject) => {
                let upload = ['load_up', 'load_down', 'reload'].includes(request.type),
                    getMessages = () => {
                        if (!upload) commit("CHANGE_LOADING_CHAT_MESSAGES_STATUS", true)
                        axios
                            .get(URL)
                            .then(async (resp) => {
                                if (request.type === 'load_down') {
                                    let messages = await document.querySelector('.unit_correspondence_messages-content');
                                    if (messages) await messages.scrollBy({top: -60, left: 0});
                                }

                                if (upload) {
                                    await commit("SAVE_MESSAGES_LIST", {
                                        "type": request.type,
                                        "messages": resp.data.data.messages
                                    })
                                } else {
                                    let chat = resp.data.data.chat;

                                    await commit("SAVE_CHAT_ID", chat.id)
                                    await commit("CHANGE_MESSAGES_LIST_ALL_LOADED", false)
                                    await commit("SAVE_MESSAGES_LIST", {
                                        "type": "update",
                                        "messages": resp.data.data.messages
                                    })

                                    let has_chat = await state.chats_list.findIndex(old_chat => old_chat.id === chat.id)
                                    if (!~has_chat) {
                                        if (!chat.last_message) chat['last_message'] = {
                                            "author_uuid": getters.USER.uuid,
                                            "chat_id": chat.id,
                                            "id": null,
                                            "read_at": null,
                                            "received_at": null,
                                            "text": null
                                        }
                                        await commit("SAVE_NEW_CHAT", chat)
                                    }
                                }
                                if ((!request.type || request.type === 'load_up' || request.type === 'reload') && resp.data.data.messages.length < state.messages_load_limit) {
                                    commit("CHANGE_MESSAGES_LIST_ALL_LOADED", true)
                                }

                                await resolve(request.uuid)
                            })
                            .catch(err => {
                                commit("SAVE_COMPANION_UUID", null)
                                commit("SAVE_CHAT_ID", null)
                                commit("SAVE_MESSAGES_LIST", {
                                    "type": "update",
                                    "messages": []
                                })
                                reject(err)
                            })
                            .finally(() => commit("CHANGE_LOADING_CHAT_MESSAGES_STATUS", false))
                    };

                if (request.type === 'load_up') {
                    URL += '&direction=up&last_message_id=' + state.messages_list[state.messages_list.length - 1].id
                    getMessages()
                } else if (request.type === 'load_down') {
                    URL += '&direction=down&last_message_id=' + state.messages_list[0].id
                    getMessages()
                } else if (request.type === 'update' || request.type === 'reload') {
                    getMessages()
                } else {
                    Vue.$vlf
                        .getItem(state.companion_uuid)
                        .then(async resp => {
                            if (!resp || !resp.length) getMessages()
                            else {
                                await commit("SAVE_CHAT_ID", request.chat_id || resp[0]?.chat_id)
                                await commit("CHANGE_MESSAGES_LIST_ALL_LOADED", false)

                                let chat_id = request.chat_id || resp[0]?.chat_id,
                                    chat = state.chats_list.find(chat => chat.id === chat_id)

                                let condition1, condition2, condition3, condition4;

                                if (chat) {
                                    condition1 = resp[0].id === chat.last_message.id;
                                    condition2 = chat.unread_messages_count === 0;
                                    condition3 = resp[0].read_at === chat.last_message.read_at;
                                    condition4 = resp[0].deleted_at === chat.last_message.deleted_at;
                                }

                                if (condition1 && condition2 && condition3 && condition4) {
                                    await commit("SAVE_MESSAGES_LIST", {
                                        "type": "output",
                                        "messages": resp
                                    })
                                    if (resp.length < state.messages_load_limit) await commit("CHANGE_MESSAGES_LIST_ALL_LOADED", true)
                                    await resolve(request.uuid)
                                } else await getMessages()
                            }
                        })
                        .catch(err => {
                            console.error('WOLA:', err)
                            getMessages()
                        })
                }
            })
        },
        READ_ALL_MESSAGES({commit, dispatch}, chat_id) {
            return new Promise((resolve, reject) => {
                axios
                    .post(this.$api_url_chat + '/chat/' + chat_id + '/read-all-messages')
                    .then(async () => {
                        await commit("UPDATE_READ_AT", {
                            "is_all_messages": true,
                            "read_at": Date.now()
                        })
                        await dispatch("UPDATE_CHAT_READ_AT", {
                            "chat_id": chat_id,
                            "unread_messages_count": 0
                        })
                        await resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        SEARCH_MESSAGES({state, commit}, query) {
            return new Promise((resolve, reject) => {
                if (query.length < 4) {
                    commit("SAVE_FOUND_MESSAGES", [])
                    commit("SAVE_FOUND_MESSAGES_EMPTY", true)
                    resolve()
                    return
                }

                commit("SAVE_FOUND_MESSAGES_EMPTY", false)

                axios
                    .get(this.$api_url_chat + '/messages/search', {
                        params: {
                            'chat_id': state.chat_id,
                            'limit': 20,
                            'query': query
                        }
                    })
                    .then((resp) => {
                        commit("SAVE_FOUND_MESSAGES", resp.data.data.messages || [])
                        if (!resp.data.data.messages.length) commit("SAVE_FOUND_MESSAGES_EMPTY", true)
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        UPDATE_NEW_MESSAGE({state, commit, rootState}, message) {
            let message_index = state.messages_list.findIndex(msg => msg.client_message_id === message.client_message_id);

            if (rootState.cache.chat_unsent_messages.length > 0) commit("SAVE_UNSENT_MESSAGE", {message});

            if (~message_index) {
                let local_message = state.messages_list[message_index]
                local_message.read_at = message.read_at;
                local_message.id = message.id;

                if (message.image) {
                    local_message.image = Object.assign(local_message.image, message.image);
                    this.$chat_images.set(local_message.image.actual_file_name, local_message.image.base);
                    this.$chat_images.set(local_message.image.preview_file_name, local_message.image.base);
                } else if (message.audio) {
                    local_message.audio = Object.assign({'blob': local_message.audio.blob}, message.audio);
                    this.$chat_voices.set(local_message.audio.actual_file_name, local_message.audio.blob);
                }
            } else commit("SAVE_NEW_MESSAGE", message)
        },
        UPDATE_CHAT_READ_AT({state, getters, commit}, message) {
            let chatIndex = state.chats_list.findIndex(ct => ct.id === message.chat_id);

            if (~chatIndex) {
                if ('read_at' in message) {
                    if (message.is_all_messages) {
                        state.chats_list[chatIndex].last_message.read_at = message.read_at;
                    } else if (message.message_ids.length) {
                        let read_last_message = message.message_ids.includes(state.chats_list[chatIndex].last_message.id);
                        if (read_last_message) state.chats_list[chatIndex].last_message.read_at = message.read_at;
                    }
                }
                if ('unread_messages_count' in message) state.chats_list[chatIndex].unread_messages_count = message.unread_messages_count;

                let count = 0;
                state.chats_list.forEach(chat => count += chat.unread_messages_count);
                commit("UPDATE_UNREAD_MESSAGES_STATUS", count > 0);

                if (message.uuid !== getters.USER.uuid) state.chats_list[chatIndex].users[0].active = true;
            }
        },
        SEARCH_NEW_COMPANIONS({state, commit}, mode) {
            if (!search_timeout) search_timeout = setTimeout(() => search_update = true, 600000)

            return new Promise((resolve, reject) => {
                let URL = `${this.$api_url}/users/`,
                    params = {"limit": state.new_companions_load_limit},
                    refresh = state.new_companions_refresh || search_update;

                if (mode === "near_me") {
                    URL += "search/nearby"
                } else {
                    URL += "lang-search"
                    params["mode"] = mode || "recommended";

                    if (state.filter_geo.country.country) {
                        params["country"] = state.filter_geo.country.country;

                        if (state.filter_geo.city.city) {
                            params["city"] = state.filter_geo.city.city;
                        }
                    }
                }

                if (refresh) {
                    params["refresh_search_cache"] = '1';
                    commit("CHANGE_NEW_COMPANIONS_REFRESH", false);
                }

                axios
                    .get(URL, {params})
                    .then((resp) => {
                        if (resp.data.data.length < state.new_companions_load_limit) commit("CHANGE_NEW_COMPANIONS_REFRESH", true);

                        let request = {
                            'list': resp.data.data
                        };

                        if (refresh) request['type'] = 'update';

                        commit("SAVE_NEW_COMPANIONS", request);
                        commit("CHANGE_ERROR_SERVER", {
                            type: 'delete',
                            error: 'search_companions'
                        })
                        resolve(refresh ? 'update' : '');
                    })
                    .catch(err => {
                        commit("CHANGE_ERROR_SERVER", {
                            type: 'add',
                            error: 'search_companions'
                        })
                        reject(err)
                    })
                    .finally(() => {
                        if (search_update) {
                            search_update = false;
                            search_timeout = null;
                        }
                    })
            })
        },
        SEARCH_NEW_COMPANIONS_UNAUTHORIZED({commit, rootState}) {
            return new Promise((resolve, reject) => {
                let URL = this.$api_url + '/users-by-lang-search?native_language=' + rootState.languages.language.code,
                    learning_langs = [rootState.languages.language_t.code];

                if (rootState.languages.last_languages.length) {
                    rootState.languages.last_languages.forEach(lang => {
                        if (!learning_langs.includes(lang.code)) learning_langs.push(lang.code)
                    })
                }

                for (let lang of learning_langs) URL += `&learn_languages[]=${lang}`

                axios
                    .get(URL)
                    .then((resp) => {
                        commit("SAVE_NEW_COMPANIONS", {
                            'list': resp.data.data,
                            'type': 'update'
                        });
                        commit("CHANGE_ERROR_SERVER", {
                            type: 'delete',
                            error: 'search_companions'
                        });
                        resolve();
                    })
                    .catch(err => {
                        commit("CHANGE_ERROR_SERVER", {
                            type: 'add',
                            error: 'search_companions'
                        })
                        reject(err)
                    })
            })
        },
        GET_UNREAD_MESSAGES_COUNT({commit}) {
            axios
                .get(this.$api_url_chat + '/chat/are-there-unread-messages')
                .then((resp) => commit("UPDATE_UNREAD_MESSAGES_STATUS", resp.data))
        },
        LOAD_MESSAGES_FOR_SCROLL({state, commit}, id) {
            return new Promise((resolve, reject) => {
                axios
                    .get(this.$api_url_chat + '/messages', {
                        params: {
                            'chat_id': state.chat_id,
                            'limit': 30,
                            'message_id': id
                        }
                    })
                    .then((resp) => {
                        commit("CHANGE_MESSAGES_LIST_ALL_LOADED", false)
                        commit("SAVE_MESSAGES_LIST", {
                            "type": "update",
                            "messages": resp.data.data.messages
                        })
                        resolve()
                    })
                    .catch(err => reject(err))
            })
        },
        MUTE_CHAT({commit}, data) {
            return new Promise((resolve, reject) => {
                let URL = this.$api_url_chat + '/chat/' + data.id;

                if (data.type === 'mute') URL += '/mute'
                else URL += '/unmute'

                axios
                    .post(URL)
                    .then(() => {
                        commit("UPDATE_CHAT_MUTED", {id: data.id, mute: data.type === 'mute'})
                        resolve();
                    })
                    .catch(err => reject(err))
            })
        },
        SEARCH_USER_BY_NICKNAME({commit}, nickname) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`${this.$api_url}/user/search?` + new URLSearchParams({'keyword': nickname}))
                    .then((resp) => resolve(resp.data.data))
                    .catch((error) => reject(error))
                    .finally(() => commit)
            })
        },
        // eslint-disable-next-line no-empty-pattern
        GET_TIME({}, data) {
            let REFERENCE = moment(),
                TODAY = REFERENCE.clone().startOf('day'),
                YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day'),
                A_WEEK_OLD = REFERENCE.clone().subtract(6, 'days').startOf('day'),
                A_YEAR_OLD = REFERENCE.clone().startOf('year');

            function isToday(momentDate) {
                return momentDate.isSame(TODAY, 'd');
            }

            function isYesterday(momentDate) {
                return momentDate.isSame(YESTERDAY, 'd');
            }

            function isWithinAWeek(momentDate) {
                return momentDate.isAfter(A_WEEK_OLD);
            }

            function isThisYear(momentDate) {
                return momentDate.isAfter(A_YEAR_OLD);
            }

            function isLastYear(momentDate) {
                return !isThisYear(momentDate);
            }

            let valid_date = moment.unix(data.date)

            if (isToday(valid_date)) {
                if (data.type === 'time') return valid_date.format('HH:mm')
                else return valid_date.calendar().split(/[\s,]+/)[0]
            } else if (isYesterday(valid_date)) return valid_date.calendar().split(/[\s,]+/)[0]
            else if (isWithinAWeek(valid_date)) return valid_date.format('ddd')
            else if (isThisYear(valid_date)) return valid_date.format('D MMM')
            else if (isLastYear(valid_date)) return valid_date.format('DD.MM.YYYY')
            else return ""
        },
        GET_COUNTRIES({commit}, payload) {
            return new Promise((resolve, reject) => {
                if (!payload.query || (payload.country_code && (payload.query.length < 3))) {
                    resolve([]);
                    return;
                }

                let URL = `${this.$api_url}/countries`;

                if (!payload.country_code) URL += `?query=${payload.query}`
                else URL += `/${payload.country_code}/cities?query=${payload.query}`

                axios
                    .get(URL)
                    .then((resp) => resolve(resp.data.data))
                    .catch((error) => reject(error))
                    .finally(() => commit)
            })
        },
    },
    mutations: {
        SAVE_CHATS_LIST: (state, list) => state.chats_list = list,
        CONCAT_CHATS_LIST: (state, list) => state.chats_list = state.chats_list.concat(list),
        ADD_NEW_CHAT_IN_LIST: (state, chat) => {
            let new_chat_uniq = state.chats_list.findIndex(ct => ct.id === chat.id)
            if (!~new_chat_uniq) {
                state.chats_list.unshift(chat)
                state.new_chat = {}
            }
        },
        SAVE_NEW_CHAT: (state, chat) => {
            state.new_chat = chat
        },
        SAVE_DELETE_CHAT: (state, chat) => {
            state.delete_chat = chat
        },
        DELETE_CHATS_FROM_LIST: (state, ids) => {
            state.chats_list = state.chats_list.filter(chat => !ids.includes(chat.id))
        },
        HIDE_CHATS_FROM_LIST: (state, data) => {
            let indexChat = state.chats_list.findIndex(chat => chat.id === data.id)
            if (~indexChat) state.chats_list[indexChat]['access_status'] = data.status
            else if (data.id === state.new_chat.id) state.new_chat['access_status'] = data.status
        },
        SAVE_MESSAGES_LIST: async (state, data) => {
            if (data.type === "output") state.messages_list = data.messages;
            else {
                let messages = await data.messages.reverse();
                if (data.type === "update" || data.type === "reload") state.messages_list = messages;
                else if (data.type === "load_up") state.messages_list = state.messages_list.concat(messages);
                else if (data.type === "load_down") state.messages_list = messages.concat(state.messages_list);
            }
        },
        DELETE_MESSAGE(state, id) {
            let messageIndex = state.messages_list.findIndex(message => message.client_message_id === id)
            if (~messageIndex) state.messages_list.splice(messageIndex, 1)
        },
        CHANGE_CHATS_LIST_ALL_LOADED: (state, boolean) => state.chats_list_all_loaded = boolean,
        CHANGE_MESSAGES_LIST_ALL_LOADED: (state, boolean) => state.messages_list_all_loaded = boolean,
        CHANGE_SHOW_COMPANIONS_PAGE: (state, boolean) => state.show_companions_page = boolean,
        CHANGE_SELECT_MESSAGES_STATUS: (state, boolean) => state.select_messages_status = boolean,
        CHANGE_FORWARD_MESSAGES_STATUS: (state, boolean) => state.select_messages_status = boolean,
        CHANGE_FORWARD_MESSAGES_POPUP_STATUS: (state, boolean) => state.forward_messages_popup_status = boolean,
        CHANGE_SELECT_MESSAGES: async (state, data) => {
            if (data.type === 'clear') {
                state.select_messages = []
            } else if (data.type === 'add') {
                state.select_messages.push(data.message)
            } else if (data.type === 'delete') {
                state.select_messages = state.select_messages.filter(message => message.id !== data.message.id)
            }

            if (state.select_messages.length) {
                state.select_messages = state.select_messages.sort((a, b) => a.received_at - b.received_at)
            }
        },
        UPDATE_FORWARD_MESSAGES(state, messages) {
            let selectedChatIndex = state.chats_list.findIndex(chat => chat.id === state.chat_id)

            messages.forEach(m => {
                let local_message = state.messages_list.find(msg => msg.client_message_id === m.client_message_id);
                if (local_message) {
                    local_message.id = m.new_message_id;

                    if (~selectedChatIndex && (state.chats_list[selectedChatIndex].last_message.client_message_id === local_message.client_message_id)) {
                        state.chats_list[selectedChatIndex].last_message.id = m.new_message_id
                    }
                }
            })
        },
        SAVE_NEW_MESSAGE: (state, message) => {
            if (!state.messages_list.length) state.messages_list = [message]
            else if (state.messages_list[0].id !== message.id) state.messages_list.unshift(message)
        },
        UPDATE_COMPANION_STATUS(state, data) {
            let chat = state.chats_list.find(chat => data.uuid === chat.users[0].uuid)
            if (chat) chat.users[0].active = data.active
        },
        SAVE_COMPANION_UUID: (state, uuid) => state.companion_uuid = uuid,
        SAVE_CHAT_ID: (state, name) => state.chat_id = name,
        UPDATE_READ_AT: async (state, data) => {
            if (data.is_all_messages) {
                await state.messages_list.forEach(message => message.read_at = data.read_at)
            } else {
                await state.messages_list.forEach(message => {
                    if (data.message_ids.includes(message.id)) message.read_at = data.read_at
                })
            }
        },
        UPDATE_EDITED_MESSAGE(state, data) {
            let msg = state.messages_list.find(m => m.id === data.message_id);
            if (msg) {
                if (data.deleted_at) {
                    msg['deleted_at'] = data.deleted_at
                    msg['text'] = ""
                } else if (data.edited_at) {
                    msg['edited_at'] = data.edited_at
                    msg['text'] = data.text
                } else return;

                Vue.$vlf.setItem(state.companion_uuid, state.messages_list.slice(0, state.messages_load_limit));
            }
        },
        UPDATE_CHAT_MUTED: async (state, data) => {
            let chat = state.chats_list.find(chat => data.id === chat.id)
            if (chat) chat.is_muted = data.mute
        },
        SAVE_NEW_COMPANIONS: (state, data) => {
            if (data.type === 'update') state.new_companions_list = data.list
            else state.new_companions_list = state.new_companions_list.concat(data.list)
        },
        UPDATE_COMPANION_FRIENDSHIP_STATUS: (state, uuid) => {
            let chat = state.chats_list.find(chat => chat.users[0].uuid === uuid)
            if (chat) chat.users[0].friendship_status = "accepted"
        },
        CHANGE_NEW_COMPANIONS_REFRESH: (state, boolean) => state.new_companions_refresh = boolean,
        CHANGE_NEW_COMPANIONS_LOAD_LIMIT: (state, limit) => state.new_companions_load_limit = limit,
        UPDATE_UNREAD_MESSAGES_STATUS: (state, boolean) => state.unread_messages_status = boolean,
        SAVE_FOUND_MESSAGES: (state, messages) => state.found_messages = messages,
        SAVE_FOUND_MESSAGES_EMPTY: (state, boolean) => state.found_messages_empty = boolean,
        CHANGE_OPENED_IMAGE_INFO: (state, info) => state.opened_image_info = info,
        CHANGE_OPENED_IMAGE_RETRY: (state, boolean) => state.opened_image_retry = boolean,
        SAVE_CHAT_NEW_COMPANION_UUID: (state, uuid) => state.chat_new_companion_uuid = uuid,
        SAVE_CHAT_FILTER_GEO: (state, geo) => state.filter_geo = geo,
        CHANGE_COMPANION_FRIENDSHIP_STATUS: (state, data) => {
            let chat = state.chats_list.find(chat => chat.users[0].uuid === data.uuid)
            if (chat) chat.users[0].friendship_status = data.status
        },
        COMPANION_BECOME_BLOCKED: (state) => {
            let chat = state.chats_list.find(chat => chat.id === state.chat_id);
            if (chat) chat.users[0].block = {reason: ''};
        },
        CLEAR_CHAT: async (state) => {
            if (this.$chat_images) {
                this.$chat_images = new Map();
            }
            if (this.$chat_voices) {
                this.$chat_voices = new Map();
            }
            await Vue.$vlf.removeItem('chatDB')
            await indexedDB.deleteDatabase('chatDB')
            await Object.assign(state, getDefaultState())
        },
        // DISCONNECT_CENTRIFUGE: (state) => state.centrifuge.disconnect()
    }
}
