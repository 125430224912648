<template>
    <div class="languages_select"
         @focusin="focusLanguages"
    >
        <div class="languages_select__item"
             :class="{'active': choose_lang}"
             @click="showList"
        >
            <div class="languages_select__item-info"
                 :class="{'active': !!flag_code}"
            >
                <div v-if="!hide_placeholder" class="languages_select__item-info-placeholder">
                    {{ placeholder }}
                </div>
                <transition name="bounce">
                    <div v-if="hide_placeholder && !flag_code" class="languages_select__item-info-placeholder ease">
                        {{ placeholder }}
                    </div>
                    <div v-else-if="flag_code" class="languages_select__item-info-name"
                         :class="{'ease': hide_placeholder}">
                        <w-flag :key="flag_code" :code="flag_code"
                                class="languages_select__item-info-name--flag none-events"/>
                        {{ bus.getLanguage(flag_code, 'name') }}
                    </div>
                </transition>
            </div>
            <template v-if="!no_actions">
                <transition name="bounce-fast" mode="out-in">
                    <div v-if="cross && flag_code"
                         key="cross"
                         class="languages_select__item-close icon"
                         @click.stop="$emit('remove')"
                    >
                        <w-svg-icon folder-name="main" icon-name="iconClose" fill="#B4B1C1"/>
                    </div>
                    <div v-else
                         key="arrow"
                         class="languages_select__item-arrow icon"
                    >
                        <w-svg-icon folder-name="profile" icon-name="iconArrowSelect"
                                    class="languages_select__item-arrow-svg" :class="{'active': choose_lang}"
                        />
                    </div>
                </transition>
            </template>
            <w-loading v-if="lang_loading || loading" blur width="20px" class="languages_select__item-loading"/>
        </div>
        <transition name="select-panel">
            <div v-if="choose_lang" key="languages"
                 class="languages_select__list"
                 :style="maximum_high ? {maxHeight: max_popup_height + 'px'} : null"
                 v-click-outside="closeList"
                 @keydown="selectItem"
            >
                <div v-if="search" class="languages_select__list-search">
                    <label>
                        <w-svg-icon class="languages_select__list-search--icon" folder-name="main" icon-name="iconSearch"/>
                        <input v-model="query"
                               ref="query"
                               class="languages_select__list-search--input"
                               :placeholder="$t('search')"
                               @input="getTransliterate"
                        />
                    </label>
                </div>
                <div v-else class="languages_select__list-search-dummy"/>
                <div class="languages_select__list-catalog scrollable hidden_scroll"
                     @scroll="scrollView"
                     @mouseleave="selected_item_index = -1"
                >
                    <template v-if="languagesList.length">
                        <div v-for="(lang, i) in languagesList"
                             :key="lang.id"
                             :ref="`lang-${i}`"
                             class="languages_select__list-catalog-item"
                             :class="{'choosed': highlight && lang.code === highlight, 'focus': selected_item_index === i}"
                             @click="chooseLang(lang)"
                             @mouseover.self="mouseOver(i)"
                        >
                            <w-flag class="languages_select__list-catalog-item-flag" :key="flag_code"
                                    :code="lang.code"/>
                            <div class="languages_select__list-catalog-item-text">{{ lang.name }}</div>
                            <w-svg-icon v-if="highlight && lang.code === highlight"
                                        class="languages_select__list-catalog-item-choosed"
                                        folder-name="auth" icon-name="iconOk"/>
                        </div>
                    </template>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {mapState} from "vuex";
import textSwitcher from "../../assets/js/text_switcher";
import bus from "@/assets/js/helper.js";

export default {
    name: "w_languages_select",
    props: {
        cross: {
            type: Boolean,
            default: false
        },
        flag_code: {
            type: String
        },
        hide_placeholder: {
            type: Boolean,
            default: false
        },
        highlight: {
            type: String
        },
        languages_list: {
            type: Array
        },
        list_blocked: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        maximum_high: {
            type: Boolean,
            default: false
        },
        no_actions: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String
        },
        search: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            bus: bus,
            query: '',
            transliterate: '',
            max_popup_height: 0,
            choose_lang: false,
            lang_loading: false,
            languages_total_count: 20,
            selected_item_index: -1
        }
    },
    computed: {
        ...mapState({
            'LANGUAGES': state => state.languages.languages,
            'USER_LANG': state => state.profile.user_languages,
        }),
        computedFullList() {
            let array = (this.languages_list || this.LANGUAGES);

            if (this.query) {
                array = array
                    .filter(l => {
                        let l_name = l.name.toLowerCase(),
                            query = this.query.toLowerCase(),
                            transliterate = this.transliterate.toLowerCase();

                        return l_name.includes(query) || l_name.includes(transliterate);
                    })
                    .sort((a, b) => {
                        let a_name = a.name.toLowerCase(),
                            b_name = b.name.toLowerCase(),
                            q = this.query.toLowerCase(),
                            t = this.transliterate.toLowerCase();

                        if ((a_name.indexOf(q) < b_name.indexOf(q)) || (a_name.indexOf(t) < b_name.indexOf(t))) return -1
                        else if ((a_name.indexOf(q) > b_name.indexOf(q)) || (a_name.indexOf(t) > b_name.indexOf(t))) return 1
                        else return 0
                    });
            }

            return array;
        },
        languagesList() {
            return this.computedFullList.slice(0, this.languages_total_count).filter(l => l && l.id);
        }
    },
    methods: {
        chooseLang(lang) {
            if (lang) {
                this.$emit('click', lang);
                this.choose_lang = false;
            }
        },
        closeList() {
            if (this.choose_lang) {
                this.languages_total_count = 20;
                this.selected_item_index = -1;
            }
            this.choose_lang = false;
            this.transliterate = this.query = '';
            this.focusLanguages();
        },
        focusLanguages() {
            setTimeout(() => this.$refs['query']?.focus(), 200);
        },
        getTransliterate() {
            this.selected_item_index = -1;
            this.transliterate = textSwitcher.convert(this.query);
            this.languages_total_count = 20;
        },
        mouseOver(count) {
            this.selected_item_index = count;
        },
        scrollView(e) {
            if (this.languages_total_count < this.LANGUAGES.length && (e.target.scrollTop + e.target.clientHeight + 100) >= e.target.scrollHeight) {
                this.languages_total_count += 10
            }
        },
        selectItem(e) {
            let x = 0;
            if (![13, 38, 40].includes(e.keyCode)) return;
            else if (e.keyCode == 38) x = -1;
            else if (e.keyCode == 40) x = 1;

            if (this.selected_item_index < 0 && x < 0) return;
            else if (this.selected_item_index === (this.computedFullList.length - 1) && x > 0) return;
            else this.selected_item_index += x

            if (this.selected_item_index >= 0) {
                this.$refs[`lang-${this.selected_item_index}`]?.[0]?.scrollIntoView({
                    block: "nearest",
                    behavior: 'auto'
                })
            }

            if (e.keyCode == 13) {
                this.chooseLang(this.computedFullList[this.selected_item_index]);
                this.choose_lang = false;
            }
            e.preventDefault()
        },
        showList() {
            if (this.maximum_high && !this.max_popup_height) {
                let page_height = document.body.clientHeight;
                let pos = this.$el.getBoundingClientRect();
                this.max_popup_height = page_height - pos.bottom.toFixed() - 10;
            }

            if (!this.list_blocked) {
                this.choose_lang = !this.choose_lang;
                this.transliterate = this.query = '';
            }
            this.focusLanguages();
        }
    },
    emits: ['remove', 'click']
}
</script>

<style lang="scss" scoped>
.languages_select {
    display: flex;
    position: relative;
    height: 60px;
    border-radius: 16px;
    background-color: var(--l_input_background);

    &__item {
        position: relative;
        height: 100%;
        min-width: 170px;
        width: 100%;
        z-index: 2;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        user-select: none;
        padding: 0 9px 0 16px;
        background-color: inherit;
        border-radius: inherit;
        box-shadow: var(--l_input_start_shadow);
        transition: background-color 200ms ease, box-shadow 200ms ease;

        &:hover {
            box-shadow: var(--l_input_hover_shadow);
        }

        &.active {
            box-shadow: var(--l_input_active_shadow);
        }

        &-info {
            height: 100%;
            width: calc(100% - 22px);
            display: flex;
            flex-direction: column;
            pointer-events: none;

            &-placeholder {
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 20px;
                font-size: 16px;
                line-height: 20px;
                transform-origin: left top;
                transition: transform 300ms ease;
                color: var(--w_color_grey6);

                &.ease {
                    transform: none !important;
                }
            }

            &-text {
                text-overflow: ellipsis;
                overflow: hidden;
                margin-right: auto;
                font-size: 16px;


                &.empty {
                    line-height: 22px;
                    color: var(--w_color_grey6);
                }
            }

            &-name {
                display: flex;
                align-items: center;
                margin: auto 0 10px;
                white-space: nowrap;

                &.ease {
                    margin: auto 0 !important;
                }

                &--flag {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    margin-right: 4px;
                }

                &:first-letter {
                    text-transform: capitalize;
                }
            }

            &.active {
                .languages_select__item-info-placeholder {
                    transform: scale(0.8) translateY(-12px);
                }
            }
        }

        &-close {
            height: 100%;
            width: 32px;
            min-width: 32px;
            pointer-events: auto;

            svg {
                width: 18px;
                height: 18px;
                min-width: 18px;
                min-height: 18px;
            }
        }

        &-arrow {
            height: 100%;
            width: 32px;
            min-width: 32px;
            pointer-events: none;

            &-svg {
                width: 24px;
                height: 24px;
                min-width: 24px;
                min-height: 24px;
                transition: transform 300ms ease;

                &.active {
                    transform: rotate(180deg);
                }

                &:deep(path) {
                    stroke: var(--w_color_grey6);
                }
            }
        }

        &-loading {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            border-radius: 16px;
        }
    }

    &__list {
        position: absolute;
        top: calc(100% + 4px);
        width: 100%;
        display: flex;
        flex-direction: column;
        min-width: 265px;
        max-height: 40vh;
        background-color: var(--l_input_background);
        border-radius: 16px;
        padding-bottom: 12px;
        overflow: hidden;
        box-shadow: 0 0 0 1px var(--w_color_grey2), 0 6px 12px rgba(0, 0, 0, 0.08);
        text-align: left;
        transform-origin: top;

        &-search {
            display: flex;
            align-items: center;
            padding: 16px 16px 10px;
            position: -webkit-sticky;
            position: sticky;
            line-height: 14px;
            z-index: 1;
            top: 0;

            &-dummy {
                height: 12px;
                min-height: 12px;
            }

            label {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                background-color: var(--w_color_grey2);
                padding: 12px 16px;
                border-radius: 12px;
            }

            &--icon {
                width: 16px;
                height: 16px;
                min-width: 16px;
                margin-right: 4px;

                &:deep(path) {
                    stroke: var(--w_color_grey6);
                }
            }

            &--input {
                font-size: 14px;
                line-height: 14px;
                padding: 0;
                width: 100%;
            }
        }

        &-catalog {
            &-all {
                text-align: left;

                &-head {
                    font-size: 13px;
                    line-height: 18px;
                    color: var(--w_color_grey3);
                    padding: 16px 20px 4px;
                }
            }

            &-item {
                display: flex;
                align-items: center;
                padding: 10px 11px;
                cursor: pointer;
                margin: 0 5px;
                border-radius: 8px;

                &.choosed {
                    background-color: var(--w_color_grey1);
                }

                &.focus {
                    background-color: var(--w_color_grey1);
                }

                &-flag {
                    margin-right: 12px;
                    width: 20px;
                    height: 20px;
                    min-width: 20px !important;
                }

                &-choosed {
                    margin-left: auto;
                }

                &-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    &:first-letter {
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .select-panel {
        &-enter-from, &-leave-to {
            transform: scale(0.95) translateY(-60px);
            max-height: 60px;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0.08);

            .w_select__options-item {
                opacity: 0;
            }
        }

        &-enter-active, &-leave-active {
            transition-property: transform, opacity, max-height, box-shadow;
            transition-duration: 300ms;
            transition-timing-function: ease;
            pointer-events: none !important;
        }
    }
}

@media (max-width: 1440px) and (min-width: 981px) {
    .languages_select {
        height: 40px;
        border-radius: 11px;

        &__item {
            min-width: 114px;
            padding: 0 6px 0 11px;

            &-info {
                width: calc(100% - 15px);

                &-placeholder {
                    height: 14px;
                    font-size: 11px;
                    line-height: 14px;
                }

                &-text {
                    font-size: 11px;

                    &.empty {
                        line-height: 15px;
                    }
                }

                &-name {
                    margin: auto 0 7px;

                    &--flag {
                        width: 11px;
                        height: 11px;
                        min-width: 11px;
                        margin-right: 3px;
                    }
                }

                &.active .languages_select__item-info-placeholder {
                    transform: scale(0.8) translateY(-8px);
                }
            }

            &-close {
                width: 22px;
                min-width: 22px;

                svg {
                    width: 12px;
                    height: 12px;
                    min-width: 12px;
                    min-height: 12px;
                }
            }

            &-arrow {
                width: 22px;
                min-width: 22px;

                &-svg {
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    min-height: 16px;
                }
            }

            &-loading {
                border-radius: 11px;
            }
        }

        &__list {
            top: calc(100% + 3px);
            min-width: 176px;
            border-radius: 11px;
            padding-bottom: 8px;

            &-search {
                padding: 11px 11px 7px;
                line-height: 9px;

                &-dummy {
                    height: 8px;
                    min-height: 8px;
                }

                label {
                    padding: 8px 11px;
                    border-radius: 8px;
                }

                &--icon {
                    width: 11px;
                    height: 11px;
                    min-width: 11px;
                    margin-right: 3px;
                }

                &--input {
                    font-size: 9px;
                    line-height: 9px;
                }
            }

            &-catalog {
                &-all-head {
                    font-size: 8px;
                    line-height: 12px;
                    padding: 11px 14px 3px;
                }

                &-item {
                    padding: 7px 8px;
                    margin: 0 3px;
                    border-radius: 5px;

                    &-flag {
                        margin-right: 8px;
                        width: 14px;
                        height: 14px;
                        min-width: 14px !important;
                    }
                }
            }
        }

        .select-panel {
            &-enter-from, &-leave-to {
                transform: scale(0.95) translateY(-40px);
                max-height: 40px;
            }
        }
    }
}

@media screen and (max-width: 980px) {

}

[data-theme='dark'] {
    .languages_select {
        &__list {
            box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);

            &-search label {
                background-color: var(--w_color_grey5);
            }

            &-catalog-item {
                &.focus {
                    background-color: var(--w_color_grey4);
                }

                &.choosed {
                    background-color: var(--w_color_grey5);
                }
            }
        }

        .select-panel {
            &-enter-from, &-leave-to {
                box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
            }
        }
    }
}
</style>
